import React from 'react'
import { Popup } from "semantic-ui-react";
import * as FaIcons from "react-icons/fa";

const ReminderEvent = ({content}) => {
    let style = {
      backgroundColor: "rgba(119, 119, 119, 0)",
    };
    return (
      <Popup
        trigger={<FaIcons.FaBell />}
        content={
          <div className="card">
            <div className="card-title">
              <span>Reminder</span>
            </div>
            <div className="card-body">
              <span>{content}</span>
            </div>
          </div>
        }
        position="top left"
        style={style}
      />
    );
}

export default ReminderEvent
