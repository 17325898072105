import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import "./../style/Settings.css";
import "./../style/NewStyleSheet.css";
import { ca } from "date-fns/locale";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

const AppointmentBooking = () => {

  const [items,setItems]=useState([]);
  const itemsnew = [
    {
      "demographic_no": 1,
      "title": "Ms",
      "last_name": "TEST",
      "first_name": "PATIENT",
      "address": "410 Esplanade",
      "city": "Ladysmith",
      "province": "BC",
      "postal": "V9G1A2",
      "phone": "705-647-1323",
      "phone2": "250-245-6400",
      "email": "mary@pickford.com",
      "myOscarUserName": null,
      "year_of_birth": "1961",
      "month_of_birth": "12",
      "date_of_birth": "23",
      "hin": "1234567897",
      "ver": "AZ",
      "roster_status": "RO",
      "roster_date": null,
      "roster_termination_date": null,
      "roster_termination_reason": null,
      "patient_status": "AC",
      "patient_status_date": null,
      "date_joined": "1999-12-31T04:00:00.000Z",
      "chart_no": "1",
      "official_lang": "English",
      "spoken_lang": null,
      "provider_no": null,
      "sex": "F",
      "end_date": "2051-01-05T04:00:00.000Z",
      "eff_date": "1999-01-05T04:00:00.000Z",
      "pcn_indicator": null,
      "hc_type": "ON",
      "hc_renew_date": "2051-01-05T04:00:00.000Z",
      "family_doctor": "<rdohip>023456</rdohip><rd>ReferringLast1new,ReferringFirst1</rd>",
      "alias": null,
      "previousAddress": null,
      "children": null,
      "sourceOfIncome": null,
      "citizenship": null,
      "sin": null,
      "country_of_origin": null,
      "newsletter": "-1",
      "anonymous": null,
      "lastUpdateUser": "999998",
      "lastUpdateDate": "2015-07-19T03:00:00.000Z",
      "name": "PATIENT TEST"
        },
    {
      name: 'JavaScript'
    },
    {
      name: 'Basic'
    },
    {
      name: 'PHP'
    },
    {
      name: 'Java'
    }
  ]

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results)
  }

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result)
  }

  const handleOnSelect = (item) => {
    // the item selected
    console.log(item);
    setState({...state, fname: item['first_name'],lname: item['last_name'],phone: item['phone'],
  email: item['email'],address: item['address'],provider: item['provider_no'],gender: item['sex'],province: item['province'],
  city: item['city'],pcode: item['postal'],hcard: item['hin'],hcardversion: item['ver'],hctype: item['hc_type'],
  hctype: item['hc_type'],language: item['official_lang']});



  }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' ,fontSize:'20px'}}>{item['name']}</span>

      </>
    )
  }
  const [state, setState] = useState({
    fname: "",
    lname: "",
    dob: "",
    phone: "",
    email: "",
    address: "",
    type: "",
    date: "",
    provider: "",
    message: "",
    patientid: "",
    title: "",
    gender: "",
    province: "",
    city: "",
    pcode: "",
    hcard: "",
    hcardversion: "",
    hctype: "",
    language: "",
    stime: "",
    etime: "",


  });
  useEffect(() => {
    function getAlldemographics() {
      var axios = require('axios');
      var qs = require('qs');
      var data = qs.stringify({

      });
      var config = {
        method: 'post',
        url: 'http://localhost:8080/test/getAllDemographicsnew',
        headers: {
          'Cookie': 'connect.sid=s%3AmsMTDXMN-i0Mmztsv3qCr2j-QPKZKupr.UnddXP1AzTm5x32PZSYHpGwueKRFl9kpsimaEowUWj8'
        },
        data: data
      };

      axios(config)
        .then(function (response) {

          setItems(...items,response.data)
        })
        .catch(function (error) {
          console.log(error);
        });

    }
    getAlldemographics();


  }, [])

  const [finaldate, setFinaldate] = useState("");
  const [finalendDate, setFinalendDate] = useState("");
  function handleChange(e) {
    // console.log(e.target.name)
    // if (e.target.name == "firstname") {
    //   setState({ ...state, fname: e.target.value });
    // }
    switch (e.target.name) {
      case "firstname":
        setState({ ...state, fname: e.target.value });
        break;
      case "lastname":
        setState({ ...state, lname: e.target.value });
        break;
      case "dob":
        setState({ ...state, dob: e.target.value });
        break;
      case "phone":
        setState({ ...state, phone: e.target.value });
        break;
      case "email":
        setState({ ...state, email: e.target.value });
        break;
      case "address":
        setState({ ...state, address: e.target.value });
        break;
      case "type":
        var id = e.target.selectedIndex;
        setState({ ...state, type: e.target[id].text });
        break;

      case "date":
        setState({ ...state, date: e.target.value });
        break;
      case "stime":
        setState({ ...state, stime: e.target.value });
        break;


      case "provider":
        setState({ ...state, provider: e.target.value });
        break;
      case "message":
        setState({ ...state, message: e.target.value });
        break;
      case "patientid":
        setState({ ...state, patientid: e.target.value });
        break;
      case "title":
        setState({ ...state, title: e.target.value });
        break;
      case "gender":
        setState({ ...state, gender: e.target.value });
        break;
      case "province":
        setState({ ...state, province: e.target.value });
        break;
      case "city":
        setState({ ...state, city: e.target.value });
        break;
      case "pcode":
        setState({ ...state, pcode: e.target.value });
        break;
      case "hcard":
        setState({ ...state, hcard: e.target.value });
        break;
      case "hcardversion":
        setState({ ...state, hcardversion: e.target.value });
        break;
      case "hctype":
        setState({ ...state, hctype: e.target.value });
        break;
      case "language":
        setState({ ...state, language: e.target.value });
        break;

    }
  }

  useEffect(() => {
    function finaldate() {
      if (state.date != "" && state.stime != "") {
        let finaldate = new Date(state.date + ' ' + state.stime);
        setFinaldate(finaldate.toISOString());

      }
    }
    finaldate();


  }, [state.stime])

  useEffect(() => {
    function finaledate() {
      if (finaldate != "") {
        let minutesToAdd = 10;
        let currentDate = new Date(state.date + ' ' + state.stime);
        let finaledate = new Date(currentDate.getTime() + minutesToAdd * 59900);

        setFinalendDate(finaledate.toISOString());

      }
    }
    finaledate();


  }, [finaldate])
  function submitALL(e) {
    e.preventDefault();

    var axios = require("axios");
    var qs = require("qs");

    // var data = qs.stringify({
    //   'date': thisday,
    //   'slotwanted': timeslotcaptured,
    //   'pid': docSelected
    // });

    var config = {
      method: "get",
      url: `https://testcf-boisterous-ardvark-pc.mybluemix.net/test/addAppointment?pid=999998&date=${state.date}&starttime=${state.stime}:00&timecost=00:09:00&name=${state.fname},${state.lname}&reason=${state.type}`,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        console.log("layer1")
        var data2 = qs.stringify({
          'patientid': state.patientid,
          'notificationTitle': "Regular Appointment",
          'notification': state.type,
          'message': state.message,
          'time': state.stime,
          'adate': state.date
        });
        var config2 = {
          method: 'post',
          url: 'https://patientserver-nice-oribi-mp.mybluemix.net/notify/notificationAdding',
          headers: {
            'Authorization': 'Bearer 04347ef6920b5dc33c811a834f0138d33543ded5',
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: data2
        };
        axios(config2)
          .then(function (response) {
            console.log("layer2")

            var data3 = qs.stringify({
              'username': state.email,
              'sdate': state.date + state.stime,
            });
            var config3 = {
              method: 'post',
              url: 'https://patientserver-nice-oribi-mp.mybluemix.net/email/emailsending',
              headers: {
                'Authorization': 'Bearer 04347ef6920b5dc33c811a834f0138d33543ded5',
                'Content-Type': 'application/x-www-form-urlencoded'
              },
              data: data3
            };

            axios(config3)
              .then(function (response) {
                console.log("layer3")
                var data4 = qs.stringify({
                  'lastname': state.lname,
                  'firstname': state.fname,
                  'title': state.title,
                  'address': state.address,
                  'city': state.city,
                  'province': state.province,
                  'Pcode': state.pcode,
                  'phone': state.phone,
                  'email': state.email,
                  'dobyear': state.dob.slice(0, 4),
                  'dobmonth': state.dob.slice(5, 7),
                  'dobday': state.dob.slice(8, 10),
                  'hcard': state.hcard,
                  'hcardversion': state.hcardversion,
                  'language': state.language,
                  'provider': state.provider,
                  'gender': state.gender,
                  'hctype': state.hctype
                });
                var config4 = {
                  method: 'post',
                  url: 'https://patientserver-nice-oribi-mp.mybluemix.net/demographic/insertDemographic',
                  headers: {
                    'Authorization': 'Bearer 04347ef6920b5dc33c811a834f0138d33543ded5',
                    'Content-Type': 'application/x-www-form-urlencoded'
                  },
                  data: data4
                };

                axios(config4)
                  .then(function (response) {
                    console.log("layer4")

                    var data5 = qs.stringify({
                      'type': state.type,
                      'sdate': finaldate.slice(0, -2) + "Z",
                      'edate': finalendDate.slice(0, -2) + "Z",

                      'patientid': response.data,
                      'firstname': state.fname,
                      'lastname': state.lname
                    });
                    console.log(data5);


                    var config5 = {
                      method: 'post',
                      url: 'https://patientserver-nice-oribi-mp.mybluemix.net/booking/createapp',
                      headers: {
                        'Authorization': 'Bearer 04347ef6920b5dc33c811a834f0138d33543ded5',
                        'Content-Type': 'application/x-www-form-urlencoded'

                      },
                      data: data5
                    };
                    console.log(config5);

                    axios(config5)
                      .then(function (response) {
                        console.log("layer5")

                        console.log(JSON.stringify(response.data));
                        window.alert("The appointment is booked!")
                      })
                  })
              })
          })
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  return (
    <div className="dashboard">
      <div className="dash-body form-left">
        <div className="header">
          <h2 className="date-title">Appointment Booking Panel</h2>
        </div>
        <form method="post">
          <div style={{ width: 400 }}>
            <ReactSearchAutocomplete
              items={items}
              onSearch={handleOnSearch}
              onHover={handleOnHover}
              onSelect={handleOnSelect}
              onFocus={handleOnFocus}
              autoFocus
              formatResult={formatResult}
              styling={{height: "44px",
                border: "1px solid #dfe1e5",
                borderRadius: "24px",
                backgroundColor: "white",
                boxShadow: "rgba(32, 33, 36, 0.28) 0px 1px 6px 0px",
                hoverBackgroundColor: "#eee",
                color: "#212121",
                fontSize: "16px",
                fontFamily: "Arial",
                iconColor: "grey",
                lineColor: "rgb(232, 234, 237)",
                placeholderColor: "grey",
                clearIconMargin: '3px 14px 0 0',
                searchIconMargin: '0 0 0 16px'}}
            />
          </div>
          <br />

          <div>
            First Name:
            <input
              type="text"
              name="firstname"
              value={state.fname}
              onChange={handleChange}
            />
          </div>
          <br />

          <div>
            Last Name:
            <input
              type="text"
              name="lastname"
              value={state.lname}
              onChange={handleChange}
            />
          </div>
          <br />
          <div>
            Title:
            <input
              type="text"
              name="title"
              value={state.title}
              onChange={handleChange}
            />
          </div>
          <br />
          <div>
            Gender:
            <input
              type="text"
              name="gender"
              value={state.gender}
              onChange={handleChange}
            />
          </div>
          <br />
          <div>
            Province:
            <input
              type="text"
              name="province"
              value={state.province}
              onChange={handleChange}
            />
          </div>
          <br />

          <div>
            City:
            <input
              type="text"
              name="city"
              value={state.city}
              onChange={handleChange}
            />
          </div>
          <br />

          <div>
            Postal Code:
            <input
              type="text"
              name="pcode"
              value={state.pcode}
              onChange={handleChange}
            />
          </div>
          <br />


          <div>
            Health Card Number:
            <input
              type="text"
              name="hcard"
              value={state.hcard}
              onChange={handleChange}
            />
          </div>
          <br />


          <div>
            Health Card Version:
            <input
              type="text"
              name="hcardversion"
              value={state.hcardversion}
              onChange={handleChange}
            />
          </div>
          <br />
          <div>
            Health Card Type:
            <input
              type="text"
              name="hctype"
              value={state.hctype}
              onChange={handleChange}
            />
          </div>
          <br />
          <div>
            Language:
            <input
              type="text"
              name="language"
              value={state.language}
              onChange={handleChange}
            />
          </div>
          <br />


          <div>
            Patient ID:
            <input
              type="text"
              name="patientid"
              value={state.patientid}
              onChange={handleChange}
            />
          </div>
          <br />
          <div>
            Date of Birth:
            <input
              type="date"
              name="dob"
              value={state.dob}
              onChange={handleChange}
            />
          </div>
          <br />
          <div>
            Mobile:
            <input
              type="text"
              name="phone"
              value={state.phone}
              onChange={handleChange}
            />
          </div>
          <br />
          <div>
            Email:
            <input
              type="text"
              name="email"
              value={state.email}
              onChange={handleChange}
            />
          </div>
          <br />
          <div>
            Address:
            <input
              type="text"
              name="address"
              value={state.address}
              onChange={handleChange}
            />
          </div>
          <br />
          <div>
            Appointment Type: (Select Appointment Type you would like to
            authorize patient to book)
            <select name="type" onChange={handleChange}>
              <option value="none">Please Select</option>
              <option value="CDM">Chronical Diseases Management</option>
              <option value="CPE">Complete Physical Exam</option>
              <option value="PAP">PAP Exam</option>
              <option value="IME">Immigration Medical Exam</option>
              <option value="MH">Mental Health</option>
              <option value="MVA">Motor Vehicle Accident (ICBC)</option>
              <option value="WCB">Worker's Compensation (WCB)</option>
              <option value="CC">Complex Care</option>
              <option value="ON">Immunization</option>
            </select>
          </div>
          <br />

          <div>
            Date:
            <input
              type="date"
              name="date"
              value={state.date}
              onChange={handleChange}
            />{" "}
          </div>
          <br />
          <div>
            Starting Time:
            <input
              type="time"
              name="stime"
              value={state.stime}
              onChange={handleChange}
            />{" "}
          </div>

          <br />
          <div>
            Provider Number:
            <input
              type="text"
              name="provider"
              value={state.provider}
              onChange={handleChange}
            />{" "}
          </div>
          <br />
          <div>
            Message to Patients: <br />
            <textarea
              rows="4"
              cols="50"
              name="message"
              value={state.message}
              onChange={handleChange}
            >
              Please book an appointment within next 3 weeks
            </textarea>
          </div>
          <br />
          <input type="button" value="Cancel" className="submit-secondary" />

          <button className="submit-primary" onClick={submitALL}>
            Submit
          </button>
        </form>
      </div>

      <div className="dash-body cal-right"></div>
    </div>
  );
};

export default withRouter(AppointmentBooking);
