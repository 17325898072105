import { combineReducers } from "redux";
import monthCalendarReducers from "./monthCalendarReducers";
import weekCalendarReducers from "./weekCalendarReducers";
import dayCalendarReducers from "./dayCalendarReducers";
import reminderReducers from "./reminderReducers";
import currentApptReducers from "./currentApptReducers";

const reducers = combineReducers({
  monthCalendarData: monthCalendarReducers,
  weekCalendarData: weekCalendarReducers,
  dayCalendarData: dayCalendarReducers,
  reminderData: reminderReducers,
  currentApptDateTime: currentApptReducers,
});

export default reducers;