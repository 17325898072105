import { ActionTypes } from "../constants/actionTypes";

const initialState = {
  dayEvents: [],
};

const dayCalendarReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_DAY_CALENDAR_DATA:
      return { ...state, dayEvents: payload };

    default:
      return state;
  }
};

export default dayCalendarReducers;
