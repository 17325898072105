import axios from 'axios';
import qs from 'qs';
import React, { useState } from "react";
import '../style/CreatingProfile.css'
import EmailValidator from 'email-validator';


export default function Preference1() {

    const [fee, setfee] = useState('');
    const [mon, setmon] = useState(false);
    const [tue, settue] = useState(false);
    const [wed, setwed] = useState(false);
    const [thu, setthu] = useState(false);
    const [fri, setfri] = useState(false);
    const [sat, setsat] = useState(false);
    const [sun, setsun] = useState(false);



    const [starttime, setStarttime] = useState('');
    const [endtime, setEndtime] = useState('');
    const [hh, sethh] = useState('');
    const [mm, setmm] = useState('');
    const [at, setat] = useState('');
    const [al, setal] = useState('');





    function handlechangefee(e) {
        setfee(e.target.value);
    }

    function handleChangem(e) {
        if (mon == false) {
            setmon(true);
        }
        else {
            setmon(false);

        }
    }
    function handleChangetu(e) {
        if (tue == false) {
            settue(true);
        }
        else {
            settue(false);

        }
    }
    function handleChangewe(e) {
        if (wed == false) {
            setwed(true);
        }
        else {
            setwed(false);

        }
    }
    function handleChangeth(e) {
        if (thu == false) {
            setthu(true)
        }
        else {
            setthu(false);

        }
    }
    function handleChangefr(e) {
        if (fri == false) {
            setfri(true);
        }
        else {
            setfri(false);

        }
    }
    function handleChangesa(e) {
        if (sat == false) {
            setsat(true);
        }
        else {
            setsat(false);

        }
    }
    function handleChangesu(e) {
        if (sun == false) {
            setsun(true);
        }
        else {
            setsun(false);

        }
    }





    function handlestarttime(e) {
        setStarttime(e.target.value);
    }
    function handleendtime(e) {
        setEndtime(e.target.value);
    }




    function handlehh(e) {
        sethh(e.target.value);
    }


    function handlemm(e) {
        setmm(e.target.value);
    }


    function handleat(e) {
        setat(e.target.value);
    }
    function handleal(e) {
        setal(e.target.value);
    }
    //create submission function, subit to fhir server
    function handleSubmit(e) {
        e.preventDefault();
        var axios = require('axios');
        var qs = require('qs');
        var data = qs.stringify({
            'practitionerid': '4',
            'userID': '3',
            'prefer_time_slot_for_new': al,
            'lunch_time': hh + ":" + mm,
            'prefer_time_slot_for_follow_up': al,
            'start_work_time': starttime,
            'end_work_time': endtime,
            'cancelation_fee': fee,
            'acceptable_appointment_cancelation_time': '2 week',
            'workday': '{' + mon + ',' + tue + ',' + wed + ',' + thu + ',' + fri + ',' + sat + ',' + sun + '}'
        });
        var config = {
            method: 'post',
            url: 'http://localhost:4000/profile/prcreate',
            headers: {
                'Authorization': 'Bearer 04347ef6920b5dc33c811a834f0138d33543ded5',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });

        console.log('hello')
        //   window.location = "/profile/step5";

    }
    const preferenceTemplete = (
        <div >
            <div className="hs-desk-create-account screen">
                <div className="flex-col2">


                    <div>
                        <h1 className="title1"> Days of Working</h1>

                        <form onSubmit={handleSubmit}>
       


                            <div>
                                <section class="app">

                                    <article class="feature1 ">
                                        <input type="checkbox" id="feature1" defaultChecked={mon} onChange={handleChangem} />
                                        <div>
                                            <span className='roboto-light-chicago-20px'>
                                                Mon
                                            </span>
                                        </div>
                                    </article>

                                    <article class="feature2">
                                        <input type="checkbox" id="feature2" defaultChecked={tue} onChange={handleChangetu} />
                                        <div>
                                            <span className='roboto-light-chicago-20px'>
                                                Tue
                                            </span>
                                        </div>
                                    </article>

                                    <article class="feature3">
                                        <input type="checkbox" id="feature3" defaultChecked={wed} onChange={handleChangewe} />
                                        <div>
                                            <span className='roboto-light-chicago-20px'>
                                                Wed
                                            </span>
                                        </div>
                                    </article>

                                    <article class="feature4">
                                        <input type="checkbox" id="feature4" defaultChecked={thu} onChange={handleChangeth} />
                                        <div>
                                            <span className='roboto-light-chicago-20px'>
                                                Thu
                                            </span>
                                        </div>
                                    </article>
                                    <article class="feature4">
                                        <input type="checkbox" id="feature4" defaultChecked={fri} onChange={handleChangefr} />
                                        <div>
                                            <span className='roboto-light-chicago-20px'>
                                                Fri
                                            </span>
                                        </div>
                                    </article>
                                    <article class="feature4">
                                        <input type="checkbox" id="feature4" defaultChecked={sat} onChange={handleChangesa} />
                                        <div>
                                            <span className='roboto-light-chicago-20px'>
                                                Sat
                                            </span>
                                        </div>
                                    </article>
                                    <article class="feature4">
                                        <input type="checkbox" id="feature4" defaultChecked={sun} onChange={handleChangesu} />
                                        <div>
                                            <span className='roboto-light-chicago-20px'>
                                                Sun
                                            </span>
                                        </div>
                                    </article>
                                </section>
                            </div>

                            <h1 className="title1"> Hours of Working</h1>
                            <div className="overlap-group7 border-1-5px-heavy-metal">
                                <input className="roboto-light-chicago-20px bordertype" type="text" value={starttime}
                                    placeholder="Start Time" onChange={handlestarttime} />
                            </div>


                            <div className="overlap-group7 border-1-5px-heavy-metal">
                                <input className="roboto-light-chicago-20px bordertype" type="text" value={endtime}
                                    placeholder="End Time" onChange={handleendtime} />
                            </div>

                            <h1 className="title1"> Lunch Time</h1>
                            <div className="overlap-group10_p border-1-5px-heavy-metal">
                                <input className="roboto-light-chicago-20px bordertype" type="text"
                                    value={hh}
                                    placeholder="HH"
                                    onChange={handlehh} />
                            </div>


                            <div className="overlap-group10_p border-1-5px-heavy-metal">
                                <input className="roboto-light-chicago-20px bordertype" type="text"
                                    value={mm}

                                    placeholder="MM"
                                    onChange={handlemm} />
                            </div>

                            <h1 className="title1"> Appointment Length</h1>
                            <div className="overlap-group10_p_p border-1-5px-heavy-metal">
                                <input className="roboto-light-chicago-20px bordertype" type="text"
                                    placeholder="Appointment Type"
                                    value={at}

                                    onChange={handleat} />
                            </div>
                            <div className="overlap-group10_p_p border-1-5px-heavy-metal">
                                <input className="roboto-light-chicago-20px bordertype" type="text"
                                    placeholder="Appointment length (Min)"
                                    value={al}
                                    onChange={handleal} />
                            </div>
                            <div className="overlap-group10_p_p border-1-5px-heavy-metal">
                                <input className="roboto-light-chicago-20px bordertype" type="text"
                                    placeholder="Appointment Type"
                                    onChange={handleat} />
                            </div>
                            <div className="overlap-group10_p_p border-1-5px-heavy-metal">
                                <input className="roboto-light-chicago-20px bordertype" type="text"
                                    placeholder="Appointment length (Min)"
                                    onChange={handleal} />
                            </div>




                            <h1 className="title1"> Cancelation Fee</h1>
                            <div className="overlap-group7 border-1-5px-heavy-metal">
                                <input className="roboto-light-chicago-20px bordertype" type="text" value={fee}
                                    placeholder="$" onChange={handlechangefee} />
                            </div>

                            <button className="overlap-group2_p" >Save Preference</button>
                        </form>



                    </div>

                </div>
                <div className="side-panel">


                </div>


            </div>

        </div>



    );

    return <div >{preferenceTemplete}</div>;
}