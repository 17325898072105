import { ActionTypes } from "../constants/actionTypes";

const initialState = {
  currentApptDateTime: [],
};

const currentApptDateTime = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_APPT_DATE_TIME:
      return { ...state, currentApptDateTime: payload };

    default:
      return state;
  }
};

export default currentApptDateTime;
