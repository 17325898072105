import React, { useState } from "react";
import "../style/CreatingProfile.css";
import "./../style/NewStyleSheet.css";

import HSLogo from "../../res/images/hs_logo_02.png";
import WelcomeImg from "../../res/images/login_img_03.png";
import { BsGlobe2 } from "react-icons/bs";

// import axios from "axios";
// import qs from "qs";
// import EmailValidator from "email-validator";
// import House from "../../res/images/house.png";
// import Minus from "../../res/images/minus.png";

const PassportDetails = () => {
  //create 2 states to store name
  // const [newfName, setNewfName] = useState('');
  // const [newlName, setNewlName] = useState('');
  // const [username, setNewusername] = useState('');
  // const [password, setNewpass] = useState('');
  // const [confirmpassword, setNewcpass] = useState('');

  // function handleChangef(e) {
  //     setNewfName(e.target.value);
  // }
  // function handleChangel(e) {
  //     setNewlName(e.target.value);
  // }
  // function handleChangeusername(e) {
  //     setNewusername(e.target.value);
  // }
  // function handleChangepass(e) {
  //     setNewpass(e.target.value);
  // }
  // function handleChangeCpass(e) {
  //     setNewcpass(e.target.value);
  // }

  // //create submission function, subit to fhir server
  // function handleSubmit(e) {
  //   e.preventDefault();
  //   window.location="/profile/step3";
  // }

  return (
    // <div className="container-center-horizontal">
    //   <div className="hs-desk-create-account screen">
    //     <div className="flex-col1">
    //       <img
    //         className="ident2"
    //         src="https://anima-uploads.s3.amazonaws.com/projects/61797edcbbae245115aa994a/releases/617fb6b78e07f93dd788d0d1/img/passport-1@2x.png"
    //       />
    //       <h1 className="title">Enter your Passport Number</h1>

    //       <div>
    //         <form onSubmit={handleSubmit}>
    //           <div className="overlap-group7 border-1-5px-heavy-metal">
    //             <input
    //               className="roboto-light-chicago-20px bordertype"
    //               type="text"
    //               value={newfName}
    //               placeholder="Country"
    //               onChange={handleChangef}
    //             />
    //           </div>
    //           <div className="overlap-group7 border-1-5px-heavy-metal">
    //             <input
    //               className="roboto-light-chicago-20px bordertype"
    //               type="text"
    //               value={newfName}
    //               placeholder="Passport Number"
    //               onChange={handleChangef}
    //             />
    //           </div>

    //           <button className="overlap-group2_1">Back</button>
    //           <button className="overlap-group2_1">Skip</button>
    //           <button className="overlap-group2_1">Next</button>
    //         </form>
    //       </div>
    //     </div>
    //     <div className="side-panel">
    //       <div className="logohealthsmartstatement">
    //         <img
    //           className="logo"
    //           src="https://anima-uploads.s3.amazonaws.com/projects/61797edcbbae245115aa994a/releases/617981f2aeaa14544428f197/img/logo@1x.png"
    //         />
    //         <div className="text-1 valign-text-middle">
    //           ASSISTED HEALTHCARE INTELLIGENCE
    //         </div>
    //       </div>
    //       <div className="overlap-group1">
    //         <div className="overlap-group-1">
    //           <div className="image-3">
    //             <img
    //               className="screenshot-2021-03-12-at-2102-1"
    //               src="https://anima-uploads.s3.amazonaws.com/projects/61797edcbbae245115aa994a/releases/617981f2aeaa14544428f197/img/screenshot-2021-03-12-at-21-02-1@2x.png"
    //             />
    //           </div>
    //           <div className="image-2">
    //             <img
    //               className="screenshot-2021-03-12-at-2104-1"
    //               src="https://anima-uploads.s3.amazonaws.com/projects/61797edcbbae245115aa994a/releases/617981f2aeaa14544428f197/img/screenshot-2021-03-12-at-21-04-1@2x.png"
    //             />
    //           </div>
    //         </div>
    //         <div className="image-1">
    //           <img
    //             className="screenshot-2021-03-12-at-2103-1"
    //             src="https://anima-uploads.s3.amazonaws.com/projects/61797edcbbae245115aa994a/releases/617981f2aeaa14544428f197/img/screenshot-2021-03-12-at-21-03-1@2x.png"
    //           />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    /* New Code Starts here ... */
    <div className="login-panel">
      <div className="login-left-panel">
        <div className="panel-holder">
          <img src={HSLogo} alt="HS_Logo" className="hs-logo-img" />
          <h2>Enter your details</h2>
          <h2>to help us serve</h2>
          <h2>you better</h2>
          <img src={WelcomeImg} alt="HS_Logo" className="hs-welcome-img-2" />
        </div>
      </div>
      <div className="login-right-panel">
        <div className="panel-holder">
          <p className="progress-bar-text">
            <strong>60%</strong> Update in progress
          </p>
          <hr id="hr01" />
          <hr className="login-passport-hr" id="hr02" />
          <div className="login-header-01">
            <div className="login-header-icon">
              <BsGlobe2 size={28} />
            </div>
            <p className="login-header-text-01">Travel Document</p>
            <p className="login-header-text-02">Passport details</p>
          </div>
          <form action="/profile/step4">
            <div>
              Country <br />
              <select name="country">
                <option value="CA">Canada</option>
                <option value="US">United States</option>
                <option value="UK">United Kingdom</option>
              </select>
            </div>
            <br />
            <div>
              Passport Number <br />
              <input type="text" />
            </div>
            <br />
            <div>
              Confirm Passport Number <br />
              <input type="text" />
            </div>
            <br />
            <input
              type="button"
              value="Save Progress"
              className="submit-secondary"
            />
            <input type="submit" value="Next" className="submit-primary" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default PassportDetails;
