import React from 'react'
import * as dateFns from "date-fns";

const MonthEvent = ({id, startTime, endTime, apNum}) => {
  return (
    <div className={`month-event`}>
        <span>
          {dateFns.format(startTime, "HH:mm")}
          {" - "}
          {dateFns.format(endTime, "HH:mm")}
        </span>
        <br />
        <span>{apNum} Patients Booked</span>
        <br />
        <span>N/A Slots Available</span>
    </div>
  );
};

export default MonthEvent
