import React, { useState } from "react";
import "../style/CreatingProfile.css";
import "./../style/NewStyleSheet.css";

import HSLogo from "../../res/images/hs_logo_02.png";
import WelcomeImg from "../../res/images/login_img_05.png";
import { BiPlusMedical } from "react-icons/bi";

import axios from "axios";
import qs from "qs";
import EmailValidator from "email-validator";
import House from "../../res/images/house.png";
import Minus from "../../res/images/minus.png";

const ClinicDetails = () => {
  //create 2 states to store name
  // const [newfName, setNewfName] = useState("");
  // const [newlName, setNewlName] = useState("");
  // const [username, setNewusername] = useState("");
  // const [password, setNewpass] = useState("");
  // const [confirmpassword, setNewcpass] = useState("");

  // function handleChangef(e) {
  //   setNewfName(e.target.value);
  // }
  // function handleChangel(e) {
  //   setNewlName(e.target.value);
  // }
  // function handleChangeusername(e) {
  //   setNewusername(e.target.value);
  // }
  // function handleChangepass(e) {
  //   setNewpass(e.target.value);
  // }
  // function handleChangeCpass(e) {
  //   setNewcpass(e.target.value);
  // }

  // //create submission function, subit to fhir server
  // function handleSubmit(e) {
  //   e.preventDefault();

  //   window.location = "/profile/step5";
  // }

  return (
    // <div className="container-center-horizontal">
    //   <div className="hs-desk-create-account screen">
    //     <div className="flex-col1">
    //       <img className="ident1" src={House} />
    //       <h1 className="title">Request to Join a clinic</h1>

    //       <div>
    //         <form onSubmit={handleSubmit}>
    //           <div className="overlap-group17 border-1-5px-heavy-metal">
    //             <input
    //               className="roboto-light-chicago-20px bordertype"
    //               type="text"
    //               value={newfName}
    //               placeholder="Clinic 1 postcode"
    //               onChange={handleChangef}
    //             />
    //           </div>{" "}
    //           <div className="overlap-group18">Processing</div>
    //           <div className="overlap-group17 border-1-5px-heavy-metal">
    //             <input
    //               className="roboto-light-chicago-20px bordertype"
    //               type="text"
    //               value={newfName}
    //               placeholder="Clinic 2 postcode"
    //               onChange={handleChangef}
    //             />
    //           </div>{" "}
    //           <div className="overlap-group18_1">Authorized</div>
    //           <img className="minus" src={Minus} />
    //           <button className="overlap-group2_1">Back</button>
    //           <button className="overlap-group2_1">Skip</button>
    //           <button className="overlap-group2_1">Next</button>
    //         </form>
    //       </div>
    //     </div>
    //     <div className="side-panel">
    //       <div className="logohealthsmartstatement">
    //         <img
    //           className="logo"
    //           src="https://anima-uploads.s3.amazonaws.com/projects/61797edcbbae245115aa994a/releases/617981f2aeaa14544428f197/img/logo@1x.png"
    //         />
    //         <div className="text-1 valign-text-middle">
    //           ASSISTED HEALTHCARE INTELLIGENCE
    //         </div>
    //       </div>
    //       <div className="overlap-group1">
    //         <div className="overlap-group-1">
    //           <div className="image-3">
    //             <img
    //               className="screenshot-2021-03-12-at-2102-1"
    //               src="https://anima-uploads.s3.amazonaws.com/projects/61797edcbbae245115aa994a/releases/617981f2aeaa14544428f197/img/screenshot-2021-03-12-at-21-02-1@2x.png"
    //             />
    //           </div>
    //           <div className="image-2">
    //             <img
    //               className="screenshot-2021-03-12-at-2104-1"
    //               src="https://anima-uploads.s3.amazonaws.com/projects/61797edcbbae245115aa994a/releases/617981f2aeaa14544428f197/img/screenshot-2021-03-12-at-21-04-1@2x.png"
    //             />
    //           </div>
    //         </div>
    //         <div className="image-1">
    //           <img
    //             className="screenshot-2021-03-12-at-2103-1"
    //             src="https://anima-uploads.s3.amazonaws.com/projects/61797edcbbae245115aa994a/releases/617981f2aeaa14544428f197/img/screenshot-2021-03-12-at-21-03-1@2x.png"
    //           />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    /* New Code Starts here ... */
    <div className="login-panel">
      <div className="login-left-panel">
        <div className="panel-holder">
          <img src={HSLogo} alt="HS_Logo" className="hs-logo-img" />
          <h2>Enter your details</h2>
          <h2>to help us serve</h2>
          <h2>you better</h2>
          <img src={WelcomeImg} alt="HS_Logo" className="hs-welcome-img" />
        </div>
      </div>
      <div className="login-right-panel">
        <div className="panel-holder">
          <p className="progress-bar-text">
            <strong>90%</strong> Update in progress
          </p>
          <hr id="hr01" />
          <hr className="login-clinic-hr" id="hr02" />
          <div className="login-header-01">
            <div className="login-header-icon">
              <BiPlusMedical size={28} />
            </div>
            <p className="login-header-text-01">Workplaces</p>
            <p className="login-header-text-02">Join Clinics</p>
          </div>
          <form action="/profile/summary">
            <div>
              Select clinic <br />
              <select name="clinic">
                <option value="IMC">Imperial Medical Clinic</option>
                <option value="SDC">The Super Duper Clinic</option>
                <option value="TOC">The Other Clinic</option>
              </select>
            </div>
            <br />
            <input
              type="button"
              value="Save Progress"
              className="submit-secondary"
            />
            <input type="submit" value="Confirm" className="submit-primary" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default ClinicDetails;
