import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setDayCalendarData } from "./../../redux/actions/calendarActions";
import DayEvent from "../HS-Events/DayEvent";
import * as dateFns from "date-fns";
import axios from "axios";
import qs from "qs";

const DyCalendar = (props) => {
  const [selectedDate, setSelectedDate] = useState(props.currentDate);
  const events = useSelector((state) => state.dayCalendarData.dayEvents);

  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedDate(props.currentDate);
  }, [props.currentDate]);

  useEffect(() => {
    fetchEventsAPI();
  }, [selectedDate]);

  const fetchEventsAPI = async () => {
    const data = qs.stringify({
      practitionerid: "3",
      actualDate: dateFns.format(selectedDate, "yyyy-MM-dd"),
    });

    const config = {
      method: "post",
      url: "https://patientserver-nice-oribi-mp.mybluemix.net/simple/weekandday",
      headers: {
        Authorization: "Bearer 04347ef6920b5dc33c811a834f0138d33543ded5",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    await axios(config)
      .then(function (response) {
        console.log(response.data);
        dispatch(setDayCalendarData(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const renderDays = () => {
    return (
      <tr className="row">
        <th className="col-time">Time</th>
        <th className="dy-col">{dateFns.format(selectedDate, "dd EEEE")}</th>
      </tr>
    );
  };

  const renderCells = () => {
    const dateFormat = "HH:mm";
    const duration = 10;
    const rows = [];

    let day = dateFns.addHours(dateFns.startOfDay(selectedDate), 1);
    let dayEnd = dateFns.addHours(dateFns.startOfDay(selectedDate), 23);

    while (day <= dayEnd) {
      rows.push(
        <tr className="row" key={day}>
          <td className="col-time">
            <span>{dateFns.format(day, dateFormat)}</span>
          </td>
          <td className="dy-col">{renderEvent(day, duration)}</td>
        </tr>
      );
      day = dateFns.addMinutes(day, duration);
    }

    return <div className="body">{rows}</div>;
  };

  const renderEvent = (day, len) => {
    return events.length > 0 ? (
      events.map((event) => {
        const { PaitentName, AppointmentStartTime, AppointmentEndTime } = event;
        return (
          <div>
            {dateFns.format(day, "ddMMyyyyHHmm") ===
              dateFns.format(
                new Date(AppointmentStartTime),
                "ddMMyyyyHHmm"
              ) && (
              <DayEvent
                Name={PaitentName}
                StartTime={new Date(AppointmentStartTime)}
                EndTime={new Date(AppointmentEndTime)}
                ISOStartTime={AppointmentStartTime}
                d={len}
              />
            )}
          </div>
        );
      })
    ) : (
      <></>
    );
  };

  return (
    <div className="calendar">
      <table className="day-calendar-body">
        {renderDays()}
        {renderCells()}
      </table>
    </div>
  );
};

export default DyCalendar;
