import axios from "axios";
import qs from "qs";
import React, { useState } from "react";
import EmailValidator from "email-validator";
import "./style/NewStyleSheet.css";
import HSLogo from "../res/images/hs_logo_02.png";
import WelcomeImg from "../res/images/login_img_01.png";

import { FaMobileAlt } from "react-icons/fa";
import { useHistory } from "react-router-dom";

const Profile = () => {
  //create 2 states to store name
  const [newfName, setNewfName] = useState("");
  const [newlName, setNewlName] = useState("");
  const [username, setNewusername] = useState("");
  const [password, setNewpass] = useState("");
  const [confirmpassword, setNewcpass] = useState("");

  let history = useHistory();

  function handleChangef(e) {
    setNewfName(e.target.value);
  }
  function handleChangel(e) {
    setNewlName(e.target.value);
  }
  function handleChangeusername(e) {
    setNewusername(e.target.value);
  }
  function handleChangepass(e) {
    setNewpass(e.target.value);
  }
  function handleChangeCpass(e) {
    setNewcpass(e.target.value);
  }

  //create submission function, subit to fhir server
  function handleSubmit(e) {
    e.preventDefault();
    var passwordm = "Please Confirm Your Password Again!";
    if (password != confirmpassword) {
      alert(passwordm);
      return;
    }
    //use EmailValidator to ensure your email address in correct format
    if (EmailValidator.validate(username) != 1) {
      alert("Your Email Address Is Not The Correct Format!");
      return;
    }
    if (newfName != "" && newlName != "") {
      var data = qs.stringify({
        family: newfName,
        given: newlName,
      });
      var config = {
        method: "post",
        url: "http://localhost:4000/profile/pfcreate",
        headers: {
          Authorization: "Bearer 04347ef6920b5dc33c811a834f0138d33543ded5",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          var data = qs.stringify({
            username: username,
            password: password,
            grant_type: "password",
            client_id: "",
            client_secret: "",
          });
          var config = {
            method: "post",
            url: "http://localhost:4000/auth/register",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            data: data,
          };

          axios(config)
            .then(function (response) {
              console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
              console.log(error);
            });
          console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log(error);
        });

      window.location = "/profile/step1";
    }
  }

  return (
    // <form classnameName="stack-small" onSubmit={handleSubmit}>
    //     <div classnameName="form-group">
    //         <label classnameName="doctor-label" >
    //             First Name
    //         </label>
    //         <input classnameName="doctor-text" type="text"
    //             value={newfName}
    //             onChange={handleChangef}
    //             />
    //     </div>

    //     <div classnameName="form-group">
    //         <label classnameName="doctor-label" >
    //             Last Name
    //         </label>
    //         <input classnameName="doctor-text" type="text"
    //             value={newlName}
    //             onChange={handleChangel} />
    //     </div>

    //     <div classnameName="btn-group">

    //         <button type="submit" classnameName="btn btn__primary doctor-edit">
    //             Save
    //         </button>
    //     </div>
    // </form>

    // <div className="container-center-horizontal">
    //   <div className="hs-desk-create-account screen">
    //     <div className="flex-col">
    //       <img
    //         className="ident"
    //         src="https://anima-uploads.s3.amazonaws.com/projects/61797edcbbae245115aa994a/releases/617981f2aeaa14544428f197/img/ident@2x.svg"
    //       />
    //       <h1 className="title">Create Account</h1>

    //       <div>
    //         <form onSubmit={handleSubmit}>
    //           <div className="overlap-group7 border-1-5px-heavy-metal">
    //             <label>
    //               Name:
    //               <input
    //                 className="roboto-light-chicago-20px bordertype"
    //                 type="text"
    //                 value={newfName}
    //                 placeholder="Legal First Name"
    //                 onChange={handleChangef}
    //               />
    //             </label>
    //           </div>
    //           <div className="overlap-group border-1-5px-heavy-metal">
    //             <input
    //               className="roboto-light-chicago-20px bordertype"
    //               type="text"
    //               value={newlName}
    //               placeholder="Legal Last Name"
    //               onChange={handleChangel}
    //             />
    //           </div>
    //           <div className="overlap-group border-1-5px-heavy-metal">
    //             <input
    //               className="roboto-light-chicago-20px bordertype"
    //               type="text"
    //               value={username}
    //               placeholder="Email"
    //               onChange={handleChangeusername}
    //             />
    //           </div>
    //           <div className="overlap-group border-1-5px-heavy-metal">
    //             <input
    //               className="roboto-light-chicago-20px bordertype"
    //               type="text"
    //               value={password}
    //               placeholder="Password"
    //               onChange={handleChangepass}
    //             />
    //           </div>
    //           <div className="overlap-group border-1-5px-heavy-metal">
    //             <input
    //               className="roboto-light-chicago-20px bordertype"
    //               type="text"
    //               value={confirmpassword}
    //               placeholder="Confirm Password"
    //               onChange={handleChangeCpass}
    //             />
    //           </div>
    //           <div className="flex-row">
    //             <input type="checkbox" />
    //             <label className="text-3 roboto-medium-zuccini-16px">
    //               I agree to the Terms &amp; Conditions
    //             </label>
    //           </div>
    //           <button className="overlap-group2">Create Account</button>
    //         </form>
    //       </div>
    //       <p className="text-2">
    //         <span className="span0">Already have an account? </span>
    //         <span className="span1 roboto-medium-zuccini-16px">Sign In.</span>
    //       </p>
    //     </div>
    //     <div className="side-panel">
    //       <div className="logohealthsmartstatement">
    //         <img
    //           className="logo"
    //           src="https://anima-uploads.s3.amazonaws.com/projects/61797edcbbae245115aa994a/releases/617981f2aeaa14544428f197/img/logo@1x.png"
    //         />
    //         <div className="text-1 valign-text-middle">
    //           ASSISTED HEALTHCARE INTELLIGENCE
    //         </div>
    //       </div>
    //       <div className="overlap-group1">
    //         <div className="overlap-group-1">
    //           <div className="image-3">
    //             <img
    //               className="screenshot-2021-03-12-at-2102-1"
    //               src="https://anima-uploads.s3.amazonaws.com/projects/61797edcbbae245115aa994a/releases/617981f2aeaa14544428f197/img/screenshot-2021-03-12-at-21-02-1@2x.png"
    //             />
    //           </div>
    //           <div className="image-2">
    //             <img
    //               className="screenshot-2021-03-12-at-2104-1"
    //               src="https://anima-uploads.s3.amazonaws.com/projects/61797edcbbae245115aa994a/releases/617981f2aeaa14544428f197/img/screenshot-2021-03-12-at-21-04-1@2x.png"
    //             />
    //           </div>
    //         </div>
    //         <div className="image-1">
    //           <img
    //             className="screenshot-2021-03-12-at-2103-1"
    //             src="https://anima-uploads.s3.amazonaws.com/projects/61797edcbbae245115aa994a/releases/617981f2aeaa14544428f197/img/screenshot-2021-03-12-at-21-03-1@2x.png"
    //           />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    /* New Code Starts here ... */

    <div className="login-panel">
      <div className="login-left-panel">
        <div className="panel-holder">
          <img src={HSLogo} alt="HS_Logo" className="hs-logo-img" />
          <h2>Your place to work</h2>
          <h2>Plan. Create. Control.</h2>
          <img src={WelcomeImg} alt="HS_Logo" className="hs-welcome-img" />
        </div>
      </div>
      <div className="login-right-panel">
        <div className="panel-holder">
          <p className="progress-bar-text">
            <strong>20%</strong> Update in progress
          </p>
          <hr id="hr01" />
          <hr id="hr02" />
          <div className="login-header-01">
            <div className="login-header-icon">
              <FaMobileAlt size={28} />
            </div>
            <p className="login-header-text-01">Lets get you started</p>
            <p className="login-header-text-02">Create an Account</p>
          </div>
          <form action="/profile/step1">
            <div>
              Title <br />
              <select>
                <option value="dr">Dr.</option>
                <option value="mr">Mr.</option>
                <option value="miss">Ms.</option>
                <option value="misses">Mrs.</option>
                <option value="prof">Prof.</option>
              </select>
            </div>
            <br />
            <div>
              Legal First Name <br />
              <input type="text" />
            </div>
            <br />
            <div>
              Legal Last Name <br />
              <input type="text" />
            </div>
            <br />
            <div>
              Email <br />
              <input type="email" />
            </div>
            <br />
            <div>
              Password <br />
              <input type="password" />
            </div>
            <br />
            <div>
              Confirm Password <br />
              <input type="password" />
            </div>
            <br />
            <input type="button" value="Cancel" className="submit-secondary" />
            <input
              type="submit"
              value="Create Account"
              className="submit-primary"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Profile;
