import React, {useState} from "react";
import { withRouter } from "react-router";
import MonthView from "./MonthView";
import WeekView from "./WeekView";
import DayView from "./DayView";

const Doctor = () => {

  const components = {
    MonthView: <MonthView />,
    WeekView: <WeekView />,
    DayView: <DayView />
  };

  const [view, setView] = useState("DayView");

  return (
    <div>
      <div className="dash-header dash-right">
        <div className="toggle-switch">
          <button className="switch-button" onClick={() => setView("DayView")}>
            Day
          </button>
          <button className="switch-button" onClick={() => setView("WeekView")}>
            Week
          </button>
          <button className="switch-button" onClick={() => setView("MonthView")}>
            Month
          </button>
        </div>
      </div>
      <div>{components[view]}</div>
    </div>
  );
};

export default withRouter(Doctor);
