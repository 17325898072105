import { ActionTypes } from "../constants/actionTypes";

const initialState = {
  reminderEvents: [],
};

const reminderReducers = (state = initialState, { type, payload }) => {
    switch (type) {
      case ActionTypes.SET_REMINDER_DATA:
        return { ...state, reminderEvents: payload };

      default:
        return state;
    }
};

export default reminderReducers;
