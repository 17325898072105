import React from "react";
import * as dateFns from "date-fns";
import * as FiIcon from "react-icons/fi";

class Calendar extends React.Component {
  state = {
    currentMonth: this.props.currentDate,
    selectedDate: new Date(),
  };

  renderHeader() {
    const dateFormat = "MMMM yyyy";

    return (
      <div className="header row flex-middle">
        <div className="date-view">
          <span>{dateFns.format(this.state.currentMonth, dateFormat)}</span>
        </div>
        <div className="prev-btn" onClick={this.prevMonth}>
          <FiIcon.FiChevronLeft size={24} />
        </div>
        <div className="next-btn" onClick={this.nextMonth}>
          <FiIcon.FiChevronRight size={24} />
        </div>
      </div>
    );
  }

  nextMonth = () => {
    this.setState({
      currentMonth: dateFns.addWeeks(this.state.currentMonth, 1),
    });
    this.props.onNext();
  };

  prevMonth = () => {
    this.setState({
      currentMonth: dateFns.subWeeks(this.state.currentMonth, 1),
    });
    this.props.onPrev();
  };

  renderDays() {
    const dateFormat = "EEEEE";
    const days = [];

    let startDate = dateFns.startOfWeek(this.state.currentMonth);

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {dateFns.format(dateFns.addDays(startDate, i), dateFormat)}
        </div>
      );
    }

    return <div className="days row">{days}</div>;
  }

  renderCells() {
    const { currentMonth, selectedDate } = this.state;
    const monthStart = dateFns.startOfMonth(currentMonth);
    const monthEnd = dateFns.endOfMonth(monthStart);
    const startDate = dateFns.startOfWeek(monthStart);
    const endDate = dateFns.endOfWeek(monthEnd);

    const dateFormat = "dd";
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = "";

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = dateFns.format(day, dateFormat);
        days.push(
          <div
            className={`col cell ${
              !dateFns.isSameWeek(day, currentMonth)
                ? "disabled"
                : dateFns.isSameDay(day, selectedDate)
                ? "selected highlighted"
                : dateFns.isSameWeek(day, currentMonth)
                ? "highlighted"
                : ""
            }`}
            key={day}
          >
            <span className="number">{formattedDate}</span>
          </div>
        );
        day = dateFns.addDays(day, 1);
      }
      rows.push(
        <div className="row" key={day}>
          {days}
        </div>
      );
      days = [];
    }

    return <div className="body">{rows}</div>;
  }

  onDateClick = (day) => {
    this.setState({
      selectedDate: day,
    });
  };

  render() {
    return (
      <div className="m-calendar">
        {this.renderHeader()}
        {this.renderDays()}
        {this.renderCells()}
      </div>
    );
  }
}

export default Calendar;
