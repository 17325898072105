import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import "./../style/NewStyleSheet.css";
// import Select from 'react-select';

// const options = [
//   { value: 'N', label: 'Off' },
//   { value: 'Yes', label: 'Warning' },
//   { value: 'Day', label: 'Same Day' },
//   { value: 'Wk', label: 'Same Week' },
//   { value: 'Onc', label: 'On-call Urgent' },

// ];

import { timeIntervalData, templateCodeData } from "../../model/data";

const ApptTimeSlots = () => {
  const [timeInterval, setTimeInterval] = useState(timeIntervalData[0].value);
  const [timecode, setTimecode] = useState({});

  useEffect(() => {
    renderTimeSlotTable();
  }, [timeInterval]);

  useEffect(() => {

    const temp = () => {
      setTimecode({})
    }
    temp();
  }, [timeInterval]);

  useEffect(() => {
    function changetimecode() {
      let slotOneday = 24 * 60 / timeInterval;
      let timecodestring = '';

      for (let i = 0; i < slotOneday; i++) {
        timecodestring = timecodestring + "_";
      }
      setNewtemplate({ ...newTemplate, timecode: timecodestring });
    }
    changetimecode();


  }, [timeInterval]);




  const [templateCode, setTemplateCode] = useState([]);
  const [newTemplateCode, setNewTemplateCode] = useState({
    id: "",
    code: "",
    description: "",
    duration: "",
    color: "",
    confirm: "",
    bookinglimit: "",
  });

  const [newTemplate, setNewtemplate] = useState({
    provider_no: "",
    name: "",
    summary: "",
    timecode: "________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________",
  })
  // const [temp,setTemp]=useState

  useEffect(() => {
    function getFinaltimecode() {
      let slotposition1 = Object.keys(timecode);

      let str = newTemplate['timecode'].split('');
      slotposition1.forEach((key, index) => {
        str[key] = timecode[key];
      });
      str=str.join('');
      setNewtemplate({...newTemplate,timecode:str})
    }
    getFinaltimecode();


  }, [timecode]);
  useEffect(() => {
    function getTempleteCode() {
      var axios = require("axios");

      var config = {
        method: "post",
        url: "https://testcf-boisterous-ardvark-pc.mybluemix.net/test/getTempleteCode",
      };

      axios(config)
        .then(function (response) {
          setTemplateCode(...templateCode, response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    getTempleteCode();
  }, []);



  function handleChangeTcode(e) {
    // console.log(e.target.name)
    // if (e.target.name == "firstname") {
    //   setState({ ...state, fname: e.target.value });
    // }

    switch (e.target.name) {
      case "template":
        setNewTemplateCode({ ...newTemplateCode, id: e.target.value });
        break;
      case "code":
        setNewTemplateCode({ ...newTemplateCode, code: e.target.value });
        break;
      case "description":
        setNewTemplateCode({ ...newTemplateCode, description: e.target.value });
        break;
      case "duration":
        setNewTemplateCode({ ...newTemplateCode, duration: e.target.value });
        break;
      case "color":
        setNewTemplateCode({ ...newTemplateCode, color: e.target.value });
        break;
      case "confirm":
        setNewTemplateCode({ ...newTemplateCode, confirm: e.target.value });
        break;
      case "bookinglimit":
        setNewTemplateCode({
          ...newTemplateCode,
          bookinglimit: e.target.value,
        });
        break;
    }
  }
  function handleChangeTemplate(e) {
    switch (e.target.name) {
      case "templateName":
        setNewtemplate({ ...newTemplate, name: e.target.value });
        break;

      case "summary":
        setNewtemplate({ ...newTemplate, summary: e.target.value });
        break;
    }
  }

  function handleChangeTimecode(e) {
    const { name, value } = e.target;
    setTimecode(prevState => ({
      ...prevState,
      [name]: value
    }));
  }
  function editCurrent() {
    let temp;
    for (let element of templateCode) {
      if (element.id == newTemplateCode.id) {
        temp = { ...element };
      }
    }
    setNewTemplateCode({ ...newTemplateCode, ...temp });
  }
  function submitCode(e) {
    e.preventDefault();
    var axios = require("axios");
    var qs = require("qs");
    var data = qs.stringify({
      code: newTemplateCode.code,
      description: newTemplateCode.description,
      duration: newTemplateCode.duration,
      color: newTemplateCode.color,
      confirm: newTemplateCode.confirm,
      bookinglimit: newTemplateCode.bookinglimit,
    });
    var config = {
      method: "post",
      url: "https://testcf-boisterous-ardvark-pc.mybluemix.net/test/addTempleteCode",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        window.alert("You added a new template code!");
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  function submitTemplete(e) {
    e.preventDefault();
    var axios = require('axios');
    var qs = require('qs');
    var data = qs.stringify({
      'provider_no': 'Public',
      'name': newTemplate.name,
      'summary': newTemplate.summary,
      'timecode': newTemplate.timecode
    });
    var config = {
      method: 'post',
      url: 'https://testcf-boisterous-ardvark-pc.mybluemix.net/test/addScheduletemplate',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        window.alert("Your Template Is Saved!")
      })
      .catch(function (error) {
        console.log(error);
      });

  }



  const renderTimeSlotTable = () => {
    const rows = [];
    let t = 0;
    while (t < 24) {
      for (let i = 0; i < 6; i++) {
        rows.push(
          <tr>
            <td>{t++}:00</td>
            {renderTableRows(t)}
            <td>{t++}:00</td>
            {renderTableRows(t)}
            <td>{t++}:00</td>
            {renderTableRows(t)}
            <td>{t++}:00</td>
            {renderTableRows(t)}
          </tr>
        );
      }
    }

    return <table className="appt-timeslot-table">{rows}</table>;
  };

  const renderTableRows = (t) => {
    let rows = [];
    let slot = 60 / timeInterval;



    for (let i = 0; i < slot; i++) {
      rows.push(
        <td>
          <select className="appt-type-code-select-mini" name={(t - 1) * slot + i}
            onChange={handleChangeTimecode}
          >
            <option key="0">

            </option>

            {templateCode.map((item) => {
              // const { id, value } = code;

              return (

                <option value={item.code} key={item.id}>
                  {item.code}
                </option>
              );
            })}
          </select>
        </td>
      );
    }

    return rows;
  };

  return (
    <div>
      <div className="main-body form-80-left">
        <p className="main-header-text-01">Lets get you set up with a</p>
        <p className="main-header-text-02">Booking Template</p>
        <p className="main-header-text-03">
          Enter the type of appointments that you would like to allow during
          different time blocks in your day
        </p>
        <form className="main-form">
          <div className="main-form-field">
            Template Name:
            <input
              type="text"
              placeholder="Admin Calendar"
              name="templateName"
              value={newTemplate.name}
              onChange={handleChangeTemplate}
            />
          </div>
          <div className="main-form-field">
            Summary:
            <input
              type="text"
              placeholder="Description"
              name="summary"
              value={newTemplate.summary}
              onChange={handleChangeTemplate}
            />
          </div>
          <div className="main-form-field">
            Duration:
            <select
              name="time-slot-interval-select"
              onChange={(e) => {
                setTimeInterval(e.currentTarget.value)

              }}
            >
              {timeIntervalData.map((time) => {
                const { id, label, value } = time;
                return (
                  <option value={value} key={id}>
                    {label}
                  </option>
                );
              })}
            </select>
          </div>
          <br />
          {renderTimeSlotTable()}
          <input type="button" value="Cancel" className="submit-secondary" />
          <input type="button" value="Edit" className="submit-secondary" />
          <button className="submit-primary" onClick={submitTemplete}>
            Save
          </button>

        </form>
      </div>

      <div className="main-body form-20-right">
        <p className="main-header-text-01">Lets get you set up</p>
        <p className="main-header-text-02">Create your Codes</p>
        <hr />
        <form className="appt-type-code-form">
          <div>
            Template Code:
            <select name="template" onChange={handleChangeTcode}>
              <option></option>

              {templateCode.map((item, i) => {
                return (
                  <option value={item.id} key={i}>
                    {item.code} | {item.description}
                  </option>
                );
              })}
            </select>
          </div>
          <br />
          <button
            type="button"
            className="edit-secondary"
            onClick={editCurrent}
          >
            Edit
          </button>
          <hr />
          <div>
            Code:
            <input
              type="text"
              name="code"
              value={newTemplateCode.code}
              onChange={handleChangeTcode}
            />
          </div>
          <br />
          <div>
            Description:
            <input
              type="text"
              name="description"
              value={newTemplateCode.description}
              onChange={handleChangeTcode}
            />{" "}
          </div>
          <br />
          <div>
            Duration:
            {/* <select name="time-slot-interval-select">
              {timeIntervalData.map((time) => {
                const { id, label, value } = time;
                return (
                  <option value={value} key={id}>
                    {label}
                  </option>
                );
              })}
            </select> */}
            <input
              type="text"
              name="duration"
              value={newTemplateCode.duration}
              onChange={handleChangeTcode}
            />
          </div>
          <br />
          <div>
            Color:
            <input
              type="text"
              name="color"
              value={newTemplateCode.color}
              onChange={handleChangeTcode}
            />
          </div>
          <br />
          <div>
            Booking Limit:
            <input
              type="text"
              name="bookinglimit"
              value={newTemplateCode.bookinglimit}
              onChange={handleChangeTcode}
            />{" "}
          </div>
          <br />
          <div>
            Limit Type:
            <select
              name="confirm"
              value={newTemplateCode.confirm}
              onChange={handleChangeTcode}
            >
              <option value="N">Off</option>
              <option value="Yes">Warning</option>
              <option value="Day">Same Day</option>
              <option value="Wk">Same Week</option>
              <option value="Onc">On-call Urgent</option>
            </select>
          </div>
          <br />
          <button
            className="submit-secondary"
            onClick={(e) => {
              e.preventDefault();
              setNewTemplateCode({
                ...newTemplateCode,
                id: "",
                code: "",
                description: "",
                duration: "",
                color: "",
                confirm: "",
                bookinglimit: "",
              });
            }}
          >
            Cancel
          </button>
          <button className="submit-primary" onClick={submitCode}>
            Save
          </button>
        </form>
      </div>
    </div>
  );
};

export default withRouter(ApptTimeSlots);
