import React, { useState } from "react";
import { useSelector } from "react-redux";
import WkCalendar from "../../../components/HS-Calendar/WkCalendar";
import WCalendar from "../../../components/HS-Calendar/WCalendar";
import * as dateFns from "date-fns";
import SideBar from "../../../components/SideBar/SideBar";

const WeekView = () => {
  const [date, setDate] = useState(new Date());
  const [activeState, setActiveState] = useState(false);
  const events = useSelector((state) => state.weekCalendarData.weekEvents);

  const onPrev = () => {
    setDate(dateFns.subWeeks(date, 1));
  };

  const onNext = () => {
    setDate(dateFns.addWeeks(date, 1));
  };

  const weekStart = dateFns.startOfWeek(date);
  const weekEnd = dateFns.endOfWeek(date);

  return (
    <div className="dashboard">
      <div className="dash-body doc-week-left">
        <div className="doc-week-header">
          <h3 className="date-title">
            {dateFns.format(weekStart, "dd MMM, yyyy")}
            {" - "}
            {dateFns.format(weekEnd, "dd MMM, yyyy")}
          </h3>
          <button
            className="action-primary"
            onClick={() => setActiveState(true)}
          >
            + Add Event
          </button>
          {/* <div className="calendar-dropdown">
          <select className="calendar-select">
            <option vlaue="IMCVancouver">IMC Vancouver, BC</option>
            <option vlaue="IMCMississuaga">IMC Mississauga, ON</option>
            <option vlaue="IMCToronto">IMC Toronto, ON</option>
          </select>
        </div> */}
        </div>
        <WkCalendar currentDate={date} onPrev={onPrev} onNext={onNext} />
      </div>

      <div className="dash-body doc-week-right">
        <WCalendar currentDate={date} onPrev={onPrev} onNext={onNext} />
        <SideBar activeState={activeState} setActiveState={setActiveState} />
        <div className="card">
          <div className="card-title">
            <span>Summary:</span>
          </div>
          <div className="card-body">
            <span>Booked: {events.length > 0 ? events.length : "N/A"}</span>
            <br />
            <span>Available: N/A</span>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeekView;
