import React, { useState } from "react";
import "../style/CreatingProfile.css";
import "./../style/NewStyleSheet.css";

import HSLogo from "../../res/images/hs_logo_02.png";
import WelcomeImg from "../../res/images/login_img_04.png";
import { FiMapPin } from "react-icons/fi";

const Summary = () => {
  return (
    <div className="login-panel">
      <div className="login-left-panel">
        <div className="panel-holder">
          <img src={HSLogo} alt="HS_Logo" className="hs-logo-img" />
          <h2>Enter your details</h2>
          <h2>to help us serve</h2>
          <h2>you better</h2>
          <img src={WelcomeImg} alt="HS_Logo" className="hs-welcome-img-2" />
        </div>
      </div>
      <div className="login-right-panel">
        <div className="panel-holder">
          <div className="login-header-01">
            <div className="login-header-icon">
              <FiMapPin size={28} />
            </div>
            <p className="login-header-text-01">Review Details</p>
            <p className="login-header-text-02">Confirm your details</p>
          </div>
          <form action="/profile/step5">
            <div>
              Address line 1 <br />
              <input type="text" />
            </div>
            <br />
            <div>
              Address line 2 <br />
              <input type="text" />
            </div>
            <br />
            <div>
              City <br />
              <input type="text" />
            </div>
            <br />
            <div>
              Province <br />
              <select name="province">
                {/* <option value="AB">Alberta</option> */}
                <option value="BC">British Columbia</option>
                {/* <option value="MB">Manitoba</option>
                <option value="NB">New Brunswick</option>
                <option value="NL">Newfoundland and Labrador</option>
                <option value="NS">Nova Scotia</option>
                <option value="NT">Northwest Territories</option>
                <option value="NU">Nunavut</option> */}
                <option value="ON">Ontario</option>
                {/* <option value="PE">Prince Edward Island</option>
                <option value="QC">Quebec</option>
                <option value="SK">Saskatchewan</option>
                <option value="YT">Yukon</option> */}
              </select>
            </div>
            <br />
            <div>
              Postal/Zip Code <br />
              <input type="text" />
            </div>
            <br />
            <div>
              Country <br />
              <select name="country">
                <option value="CA">Canada</option>
                <option value="US">United States</option>
                <option value="UK">United Kingdom</option>
              </select>
            </div>
            <br />
            <input
              type="button"
              value="Save Progress"
              className="submit-secondary"
            />
            <input type="submit" value="Next" className="submit-primary" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Summary;
