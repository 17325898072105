import React from "react";
import styled, { css } from "styled-components";
import { useDispatch } from "react-redux";
import { setCurrentApptDateTime } from "./../../redux/actions/calendarActions";
import * as FaIcons from "react-icons/fa";
import * as FiIcons from "react-icons/fi";
import * as MdIcons from "react-icons/md";
import * as RiIcons from "react-icons/ri";
import * as dateFns from "date-fns";
import axios from "axios";
import qs from "qs";

const EventCard = styled.div`
  position: absolute;
  font-size: 100%;
  font-weight: 500;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: calc(100% - 58px);
  border: 1px solid var(--hs-gray-3-color);
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;

  height: ${(props) =>
    (dateFns.differenceInMinutes(props.end, props.start) / props.duration) *
      60 +
    (dateFns.differenceInMinutes(props.end, props.start) / props.duration) - 1}px;

  border-right: 25px solid slateblue;

  ${(props) =>
    (props.type === "regular" &&
      css`
        background-color: var(--hs-gray-5-color);
      `) ||
    (props.type === "pap" &&
      css`
        background-color: var(--hs-appt-pap-color);
      `) ||
    (props.type === "referral" &&
      css`
        background-color: var(--hs-appt-referral-color);
      `) ||
    (props.type === "video" &&
      css`
        background-color: var(--hs-appt-video-color);
      `) ||
    (props.type === "complete-physical" &&
      css`
        background-color: var(--hs-appt-comp-phy-color);
      `) ||
    (props.type === "phone" &&
      css`
        background: repeating-linear-gradient(
          45deg,
          var(--hs-appt-video-color),
          var(--hs-appt-video-color) 5px,
          var(--hs-appt-phone-color) 5px,
          var(--hs-appt-phone-color) 10px
        );
      `) ||
    (props.type === "lunch" &&
      css`
        border: 1px solid var(--hs-gray-5-color);
        background-color: var(--hs-gray-6-color);
      `)}
`;

const EventBody = styled.span`
  text-align: left;
  justify-content: center;
  padding: 8px 0;
`;

const BreakBody = styled.div`
  text-align: center;
  font-size: 150%;
  font-weight: 900;
  opacity: 50%;
`;

const PatientIcon = styled.span`
  width: fit-content;
  padding: 8px;
`;

const PatientTitle = styled.span`
  width: fit-content;
`;

const ApptTypeIcon = styled.span`
  position: absolute;
  padding-right: 5px;
  right: 0;
`;

const Module = styled.div`
  padding: 4px 0px;
`;

const IconHolder = styled.span`
  padding: 4px 8px;
  font-size: 140%;
  cursor: pointer;
`;

const DayEvent = ({ Name, StartTime, EndTime, d, ISOStartTime }) => {
  // return e.type === "lunch" ? (
  //   <EventCard type={e.type} start={e.start} end={e.end} duration={d}>
  //     <BreakBody>
  //       <div>LUNCH BREAK</div>
  //     </BreakBody>
  //   </EventCard>)
  const dispatch = useDispatch();

  const getApptInfo = () => {
    console.log("Click registered", ISOStartTime);

    const data = qs.stringify({
      practitionerid: "3",
      appointmentStartTime: ISOStartTime,
    });

    const config = {
      method: "post",
      url: "http://localhost:4000/simple/weekandday",
      headers: {
        Authorization: "Bearer 04347ef6920b5dc33c811a834f0138d33543ded5",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        dispatch(setCurrentApptDateTime(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <EventCard type={"phone"} start={StartTime} end={EndTime} duration={d}>
      <EventBody>
        <PatientIcon>
          <FaIcons.FaUser />
        </PatientIcon>
        <PatientTitle>{Name}</PatientTitle>
        {/* <ApptTypeIcon>
          {e.type === "video" ? (
            <FaIcons.FaVideo style={{ color: "#C38C00" }} />
          ) : e.type === "phone" ? (
            <FaIcons.FaPhone style={{ color: "green" }} />
          ) : (
            ""
          )}
        </ApptTypeIcon> */}
        <Module>
          <IconHolder>
            <FiIcons.FiEdit />
          </IconHolder>
          <IconHolder>
            <RiIcons.RiMoneyDollarCircleFill />
          </IconHolder>
          <IconHolder>
            <MdIcons.MdNoteAdd />
          </IconHolder>
          <IconHolder>
            <FaIcons.FaPrescription />
          </IconHolder>
          <IconHolder>
            <MdIcons.MdOutlineInfo onClick={getApptInfo} />
          </IconHolder>
        </Module>
      </EventBody>
    </EventCard>
  );
};

export default DayEvent;
