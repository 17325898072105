import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import "./../style/Settings.css";



const AppointmentTypes = () => {
  const [state, setState] = useState([]);
  const [type, setType] = useState({
    name: '',
    notes: '',
    reason: '',
    location: '',
    resources: '',
    duration: ''
  })
  useEffect(() => {
    function getAppointmenttype() {
      var axios = require('axios');

      var config = {
        method: 'post',
        url: 'https://testcf-boisterous-ardvark-pc.mybluemix.net/test/getAppointmentype',

      };

      axios(config)
        .then(function (response) {
          setState(...state, response.data);
          console.log(response.data)
        })
        .catch(function (error) {
          console.log(error);
        });

    }
    getAppointmenttype()



  }, []);

  function handleSubmit(e) {
    e.preventDefault();

    var axios = require('axios');
    var qs = require('qs');
    var data = qs.stringify({
      'name': type.name,
      'notes': type.notes,
      'reason': type.reason,
      'location': type.location,
      'resources': type.resources,
      'duration': type.duration
    });
    var config = {
      method: 'post',
      url: 'https://testcf-boisterous-ardvark-pc.mybluemix.net/test/addAppointmenttype',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        window.alert("New Appointment Type Added!")
      })
      .catch(function (error) {
        console.log(error);
      });


  }

  function handleChange(e) {
    // console.log(e.target.name)
    // if (e.target.name == "firstname") {
    //   setState({ ...state, fname: e.target.value });
    // }
    switch (e.target.name) {
      case "name":
        setType({ ...type, name: e.target.value });
        break;
      case "notes":
        setType({ ...type, notes: e.target.value });
        break;
      case "reason":
        setType({ ...type, reason: e.target.value });
        break;
      case "location":
        setType({ ...type, location: e.target.value });
        break;
      case "resources":
        setType({ ...type, resources: e.target.value });
        break;
      case "duration":
        setType({ ...type, duration: e.target.value });
        break;

    }
  }

  return (
    <div className="dashboard">
      <div className="dash-body full-form-left">
        <div className="header">
          <h2 className="date-title">Appointment Types</h2>
        </div>
        <table className="appt-type-table">
          <tr>
            <th>Name</th>
            <th>Notes</th>
            <th>Reason</th>
            <th>Location</th>
            <th>Resources</th>
            <th>Duration</th>

          </tr>
          {state.map((item, i) => {
            return (
              <tbody>
                <tr key={i}>
                  <td>{item.name}</td>
                  <td>{item.notes}</td>
                  <td>{item.reason}</td>
                  <td>{item.location}</td>
                  <td>{item.resources}</td>
                  <td>{item.duration}</td>
                </tr>
              </tbody>);
          })}


        </table>
        <div className="form-body">
          <form  onSubmit={handleSubmit}>
            <h3>Add/Edit Appointment Types:</h3>
            <div>
              Name:
              <input type="text"
                name="name"
                value={type.name}
                onChange={handleChange} />            </div>
            <br />
            <div>
              Duration:
              <input type="text"
                name="duration"
                value={type.duration}
                onChange={handleChange} />            </div>
            <br />
            <div>
              Reason:
              <input type="text"
                name="reason"
                value={type.reason}
                onChange={handleChange} />             </div>
            <br />
            <div>
              Notes:
              <input type="text"
                name="notes"
                value={type.notes}
                onChange={handleChange} />             </div>
            <br />
            <div>
              Location:
              <input type="text"
                name="location"
                value={type.location}
                onChange={handleChange} />             </div>
            <br />
            <div>
              Resources:
              <input type="text"
                name="resources"
                value={type.resources}
                onChange={handleChange} />             </div>
            <br />
            {/* 

            <div>
              Permission based:
              <select name="pb">
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <br />
            <div>
              Fee Code:
              <select name="fee">
                <option value="MSP">MSP</option>
                <option value="OHIP">OHIP</option>
              </select>
            </div>
            <br />
            <div>
              Billing Preference:
              <select name="bp">
                <option value="MSP-OHIP">MSP/OHIP</option>
                <option value="Private">Private</option>
                <option value="Both">Both</option>
              </select>
            </div>
            <br />
            <div>
              Tele-Health:
              <select name="tele">
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <br />
            <div>
              Intake/Consent Forms:
              <select name="forms">
                <option value="Intake">Inatke Form</option>
                <option value="Consent">Consent Form</option>
              </select>
            </div>
            <br />
            */}
            <button type="button"  className="submit-secondary">
            Cancel
            </button>


            <button type="submit"   className="submit-primary">
            Save
            </button>

          </form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(AppointmentTypes);
