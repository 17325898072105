import { ActionTypes } from "../constants/actionTypes";

export const setMonthCalendarData = (monthEventsData) => {
    return {
        type: ActionTypes.SET_MONTH_CALENDAR_DATA,
        payload: monthEventsData
    };
};

export const setWeekCalendarData = (weekEventsData) => {
  return {
    type: ActionTypes.SET_WEEK_CALENDAR_DATA,
    payload: weekEventsData,
  };
};

export const setDayCalendarData = (dayEventsData) => {
  return {
    type: ActionTypes.SET_DAY_CALENDAR_DATA,
    payload: dayEventsData,
  };
};

export const setReminderData = (reminderEventsData) => {
  return {
    type: ActionTypes.SET_REMINDER_DATA,
    payload: reminderEventsData,
  };
};

export const setCurrentApptDateTime = (currentApptDateTime) => {
  return {
    type: ActionTypes.SET_APPT_DATE_TIME,
    payload: currentApptDateTime,
  };
};