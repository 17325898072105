export const timeIntervalData = [
  {
    id: 1,
    label: "5 minutes",
    value: 5,
  },
  {
    id: 2,
    label: "10 minutes",
    value: 10,
  },
  {
    id: 3,
    label: "15 minutes",
    value: 15,
  },
  {
    id: 4,
    label: "20 minutes",
    value: 20,
  },
  {
    id: 5,
    label: "30 minutes",
    value: 30,
  },
];

export const templateCodeData = [
  {
    id: 0,
    label: "Please Select",
    value: null,
  },
  {
    id: 1,
    label: "A [Academic]",
    value: "A",
  },
  {
    id: 2,
    label: "B [Behavioral Science]",
    value: "B",
  },
  {
    id: 3,
    label: "C [Chart Audit Rounds]",
    value: "C",
  },
];

export const VisitHistoryData = [
  {
    id: 1,
    type: "Physical Exam",
    name: "Dr Strange, Steven",
    date: "Jan 12, 2022",
  },
  {
    id: 2,
    type: "Regular Visit",
    name: "Dr Strange, Steven",
    date: "Dec 17, 2021",
  },
  {
    id: 3,
    type: "Covid Test",
    name: "Dr Strange, Steven",
    date: "Nov 02, 2021",
  },
  {
    id: 4,
    type: "Physical Exam",
    name: "Dr Strange, Steven",
    date: "Oct 22, 2021",
  },
];

export const MedicalHistoryData = [
  {
    id: 1,
    name: "[Drug Name]",
    quantity: "[QTY]",
    method: "Spray",
    route: "Oral",
    duration: "Once before bed",
    refills: 3,
  },
  {
    id: 2,
    name: "[Drug Name]",
    quantity: "[QTY]",
    method: "Spray",
    route: "Oral",
    duration: "Once before bed",
    refills: 3,
  },
  {
    id: 3,
    name: "[Drug Name]",
    quantity: "[QTY]",
    method: "Spray",
    route: "Oral",
    duration: "Once before bed",
    refills: 3,
  },
  {
    id: 4,
    name: "[Drug Name]",
    quantity: "[QTY]",
    method: "Spray",
    route: "Oral",
    duration: "Once before bed",
    refills: 3,
  },
];
