import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setWeekCalendarData } from "./../../redux/actions/calendarActions";
import WeekEvent from "../HS-Events/WeekEvent";
import * as dateFns from "date-fns";
import axios from "axios";
import qs from "qs";

const WkCalendar = (props) => {
  const [currentDate, setCurrentDate] = useState(props.currentDate);
  const events = useSelector((state) => state.weekCalendarData.weekEvents);

  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentDate(props.currentDate);
  },[props.currentDate]);

  useEffect(() => {
    fetchEventsAPI();
  }, [currentDate]);

  const fetchEventsAPI = async () => {
    const data = qs.stringify({
      practitionerid: "3",
      startDate: dateFns.format(
        dateFns.startOfWeek(currentDate),
        "yyyy-MM-dd"
      ),
      endDate: dateFns.format(
        dateFns.endOfWeek(currentDate),
        "yyyy-MM-dd"
      ),
    });
 
    const config = {
      method: "post",
      url: "https://patientserver-nice-oribi-mp.mybluemix.net/simple/weekandday",
      headers: {
        Authorization: "Bearer 04347ef6920b5dc33c811a834f0138d33543ded5",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    await axios(config)
      .then(function (response) {
        dispatch(setWeekCalendarData(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const renderDays = () => {
    const days = [];

    let weekStart = dateFns.startOfWeek(currentDate);

    days.push(<th className="col-time">Time</th>);

    for (let i = 0; i < 7; i++) {
      days.push(
        <th className="col col-center" key={i}>
          {dateFns.format(dateFns.addDays(weekStart, i), "dd E")}
        </th>
      );
    }

    return <tr className="days row wkHeader">{days}</tr>;
  };

  const renderCells = () => {
    const weekStart = dateFns.startOfWeek(currentDate);

    const dateFormat = "HH:mm";
    const duration = 10;
    const rows = [];

    let slots = [];
    let day = dateFns.addHours(weekStart, 1);
    let dayEnd = dateFns.addHours(weekStart, 23);

    while (day <= dayEnd) {
      slots.push(
        <td className="col-time">
          <span>{dateFns.format(day, dateFormat)}</span>
        </td>
      );

      let currentDayTime = day;

      for (let i = 0; i < 7; i++) {
        currentDayTime = dateFns.addDays(day, i);
        slots.push(
          <td className={`wk-col`} key={day}>
            {renderEvent(currentDayTime, duration)}
          </td>
        );
      }

      rows.push(
        <tr className="row" key={day}>
          {slots}
        </tr>
      );
      slots = [];
      day = dateFns.addMinutes(day, duration);
    }

    return <div className="body">{rows}</div>;
  };

  const renderEvent = (day, len) => {
    return events.length > 0 ? (
      events.map((event,i) => {
        const { PaitentName, AppointmentStartTime, AppointmentEndTime } = event;
        return (
          <div>
            {dateFns.format(day, "ddMMyyyyHHmm") ===
              dateFns.format(
                new Date(AppointmentStartTime),
                "ddMMyyyyHHmm"
              ) && (
              <WeekEvent 
                Name={PaitentName}
                StartTime={new Date(AppointmentStartTime)}
                EndTime={new Date(AppointmentEndTime)}
                d={len}
                key={i}
              />
            )}
          </div>
        );
      })
    ) : (
      <></>
    );
  };

  return (
    <div className="calendar">
      <table className="calendar-body">
        {renderDays()}
        {renderCells()}
      </table>
    </div>
  );
};

export default WkCalendar;
