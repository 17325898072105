import Login from "./pages/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { useState } from "react";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import PersonalProfile from "./pages/ProfileView/PersonalProfile";
import MedicalDetails from "./pages/ProfileView/MedicalDetails";
import PassportDetails from "./pages/ProfileView/PassportDetails";
import AddressDetails from "./pages/ProfileView/AddressDetails";
import ClinicDetails from "./pages/ProfileView/ClinicDetails";
import Summary from "./pages/ProfileView/Summary";

const App = () => {
  const [isAuth] = useState(true);
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to={{ pathname: "/login" }} />
        </Route>

        <Route exact path="/profile" component={Profile} />
        <Route exact path="/profile/step1" component={PersonalProfile} />
        <Route exact path="/profile/step2" component={MedicalDetails} />
        <Route exact path="/profile/step3" component={PassportDetails} />
        <Route exact path="/profile/step4" component={AddressDetails} />
        <Route exact path="/profile/step5" component={ClinicDetails} />
        <Route exact path="/profile/summary1" component={Summary} />

        <Route exact path="/login" component={Login} />

        <ProtectedRoute path="/home" component={Home} isAuth={isAuth} />
      </Switch>
    </Router>
  );
};

export default App;
