import HSLogo from '../res/images/hs_logo.png';
import './style/Login.css'
import axios from 'axios';
import qs from 'qs';


const Login = () => {
  const handleSubmit = (e) => {

    const data = qs.stringify({
      username: e.target.username.value,
      password: e.target.password.value,
      grant_type: "password",
      client_id: "null",
      client_secret: "null",
    });

    const config = {
      method: "post",
      url: "http://localhost:4000/auth/login",
      headers: {
        Authorization: "Bearer 04347ef6920b5dc33c811a834f0138d33543ded5",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <div className="split left">
        <div className="centered">
          <h2> Welcome to HealthSmart </h2>
          <form onSubmit={handleSubmit} action="/home/dashboard">
            <input
              className="input"
              type="text"
              name="username"
              placeholder="Username"
              width="300px"
            />
            <input
              className="input"
              type="password"
              name="password"
              placeholder="Password"
              width="300px"
            />
            <button
              className="btn btn-primary btn-sign-in"
              type="Submit"
              width="300px"
            >
              Sign In
            </button>

            
          </form>
          <p> Forgot Password? </p>
        </div>
      </div>

      <div className="split right">
        <div className="centered">
          <img src={HSLogo} alt="HealthSmart Logo" width="100%" />
           <form onSubmit={()=>{console.log("hello")}} action="/profile">
          <button 
            className="btn btn-secondary btn-register"
            type="Submit"
            name="register"
          >
            Register
          </button>
          </form>

        </div>
      </div>
    </>
  );
};

export default Login
