import React from 'react'
import * as BsIcons from "react-icons/bs";
import * as BiIcons from "react-icons/bi";

export const SidebarData = [
  {
    title: "Calendar",
    path: "#",
    icon: <BsIcons.BsCalendar />,
    subNav: [
      {
        title: "Doctor View",
        path: "/home/calendar/doctor",
        icon: <BiIcons.BiCalendar />,
      },
      {
        title: "Clinic View",
        path: "/home/calendar/clinic",
        icon: <BiIcons.BiCalendar />,
      },
      {
        title: "Group View",
        path: "/home/calendar/group",
        icon: <BiIcons.BiCalendar />,
      },
    ],
  }
];