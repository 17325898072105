import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import NavLogo from "./../../res/images/nav-icon.png";
import AvatarImg from "./../../res/images/Avatar.png";
import { SidebarData } from "./SidebarData";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as BiIcons from "react-icons/bi";
import * as CgIcons from "react-icons/cg";
import * as MdIcons from "react-icons/md";
import SubMenu from "./SubMenu";
import { Sidebar } from "semantic-ui-react";

const Nav = styled.ul`
  background: var(--hs-light-green);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 47px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  overflow: hidden;
  list-style-type: none;
  position: fixed;
  top: 0;
  z-index: 1;
`;

const NavList = styled.li`
  float: left;
`;

const NavWrap = styled(Link)`
  color: var(--hs-black-color);
  display: block;
  text-align: center;
  padding: 14px 10px;
  text-decoration: none;
  font-size: small;
  font-weight: 400;

  &:hover {
    background: var(--hs-gray-6-color);
    cursor: pointer;
  }
`;

const NavbarLogo = styled.img`
  height: 46px;
  margin: 0px 10px;
`;

const NavLeft = styled.div``;

const NavRight = styled.div``;

const ProfilePic = styled.img`
  border-radius: 30px;
  height: 35px;
  margin: 6px 10px;
  border: 2px solid var(--hs-success-color);
`;

const HeroContainer = styled.div`
  padding: 10px 5px;
  line-height: 1.1;
  text-align: right;
  color: var(--hs-primary-color);
`;

const HeroTitle = styled.div`
  font-weight: 600;
`;

const HeroSubTitle = styled.div`
  font-size: x-small;
  font-weight: 500;
`;

const SearchBox = styled.div`
  background-color: white;
  margin: 8px 20px;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
`;

const SearchBar = styled.input`
  border: none;
  width: 200px;
  font-size: x-small;

  &:focus {
    outline: none;
  }
`;

const FilterIcon = styled.div`
  background-color: var(--hs-white-color);
  margin: 8px 0px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;

  &:hover {
    background-color: var(--hs-primary-color);
    color: var(--hs-white-color);
  }
`;

const NavIcon = styled(Link)`
  margin: 10px 10px;
  padding: 0 10px;
  border-radius: 30px;
  font-size: 1.25rem;
  color: var(--hs-gray-1-color);
  height: 42px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  opacity: 60%;
`;

const SidebarNav = styled.nav`
  background: var(--hs-light-green);
  width: 60px;
  margin-top: 47px;
  height: calc(100vh - 48px);
  border-radius: 10px;
  position: fixed;
  display: flex;
  z-index: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const NavText = styled.div`
  display: none;
  position: absolute;
  background-color: black;
  color: white;
  padding: 2px 5px;
  font-size: small;
  left: 60px;
  top: 10px;
`;

const SidebarWrap = styled.nav`
  width: 100%;
  position: relative;

  &:hover ${NavIcon} {
    background: var(--hs-gray-6-color);
    opacity: 90%;
    cursor: pointer;
    transition: 500ms;
  }

  &:hover ${NavText} {
    display: block;
    transition: 500ms;
  }
`;

const SidebarTop = styled.nav`
  width: 100%;
`;

const SidebarBottom = styled.nav`
  width: 100%;
`;

const Navbar = () => {
  return (
    <>
      <Nav>
        <NavLeft>
          <NavList>
            <NavbarLogo src={NavLogo} alt="nav-icon" />
          </NavList>
          <NavList>
            <NavWrap to="#">Resources</NavWrap>
          </NavList>
          <NavList>
            <NavWrap to="#">Reports</NavWrap>
          </NavList>
          <NavList>
            <NavWrap to="#">Admin</NavWrap>
          </NavList>
          <NavList>
            <NavWrap to="#">Billing</NavWrap>
          </NavList>
          <NavList>
            <NavWrap to="#">Inbox</NavWrap>
          </NavList>
          <NavList>
            <NavWrap to="#">eDoc</NavWrap>
          </NavList>
          <NavList>
            <NavWrap to="/home/booking">Booking</NavWrap>
          </NavList>
          <NavList>
            <NavWrap to="/home/appttype">Appt Types</NavWrap>
          </NavList>
        </NavLeft>
        <NavRight>
          <NavList>
            <FilterIcon>
              <BiIcons.BiFilter size={28} />
            </FilterIcon>
          </NavList>
          <NavList>
            <SearchBox>
              <SearchBar
                type="text"
                placeholder="Search Patients, Practitioners,.."
              />
              <BiIcons.BiSearchAlt size={22} />
            </SearchBox>
          </NavList>
          <NavList>
            <HeroContainer>
              <HeroTitle>Dr. Strange, Stephen</HeroTitle>
              <HeroSubTitle>Family Practitioner</HeroSubTitle>
            </HeroContainer>
          </NavList>
          <NavList>
            <ProfilePic src={AvatarImg} />
          </NavList>
        </NavRight>
      </Nav>

      <SidebarNav>
        <SidebarTop>
          <SidebarWrap>
            <NavIcon to="/home/dashboard">
              <FaIcons.FaHome />
            </NavIcon>
            <NavText>Home</NavText>
          </SidebarWrap>
          <SidebarWrap>
            <NavIcon to="/home/calendar/doctor">
              <BiIcons.BiCalendar />
            </NavIcon>
            <NavText>Patient</NavText>
          </SidebarWrap>
          <SidebarWrap>
            <NavIcon to="/home/calendar/clinic">
              <BiIcons.BiCalendar />
            </NavIcon>
            <NavText>Clinic</NavText>
          </SidebarWrap>

          <SidebarWrap>
            <NavIcon to="/home/calendar/group">
              <BiIcons.BiCalendar />
            </NavIcon>
            <NavText>Group</NavText>
          </SidebarWrap>
        </SidebarTop>

        <SidebarBottom>
          <SidebarWrap>
            <NavIcon to="/home/appttimeslots">
              <MdIcons.MdOutlineGroup />
            </NavIcon>
            <NavText>Timeslots</NavText>
          </SidebarWrap>

          <SidebarWrap>
            <NavIcon to="/home/weekscheduler">
              <CgIcons.CgProfile />
            </NavIcon>
            <NavText>Scheduler</NavText>
          </SidebarWrap>

          <SidebarWrap>
            <NavIcon to="/home/preference1">
              <AiIcons.AiFillSetting />
            </NavIcon>
            <NavText>Preferences</NavText>
          </SidebarWrap>

          <SidebarWrap>
            <NavIcon to="/">
              <BiIcons.BiLogOut />
            </NavIcon>
            <NavText>Logout</NavText>
          </SidebarWrap>
        </SidebarBottom>

        {/* <SidebarWrap>
          {SidebarData.map((item, index) => {
            return <SubMenu item={item} key={index} />;
          })}
        </SidebarWrap> */}
      </SidebarNav>
    </>
  );
};

export default Navbar;
