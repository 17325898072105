import React from "react";
import styled, { css } from "styled-components";
import * as FaIcons from "react-icons/fa";
import * as dateFns from "date-fns";

const EventCard = styled.div`
  position: absolute;
  font-size: 80%;
  font-weight: 600;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: calc(100% / 7.4);
  border: 1px solid var(--hs-gray-3-color);
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  background-color: var(--hs-gray-5-color);

  height: ${(props) =>
    (dateFns.differenceInMinutes(props.end, props.start) / props.duration) *
      36 +
    (dateFns.differenceInMinutes(props.end, props.start) / props.duration) - 1}px;

  border-right: 10px solid slateblue;

  ${(props) =>
    (props.type === "regular" &&
      css`
        background-color: var(--hs-gray-5-color);
      `) ||
    (props.type === "pap" &&
      css`
        background-color: var(--hs-appt-pap-color);
      `) ||
    (props.type === "referral" &&
      css`
        background-color: var(--hs-appt-referral-color);
      `) ||
    (props.type === "video" &&
      css`
        background-color: var(--hs-appt-video-color);
      `) ||
    (props.type === "complete-physical" &&
      css`
        background-color: var(--hs-appt-comp-phy-color);
      `) ||
    (props.type === "phone" &&
      css`
        background: repeating-linear-gradient(
          45deg,
          var(--hs-appt-video-color),
          var(--hs-appt-video-color) 5px,
          var(--hs-appt-phone-color) 5px,
          var(--hs-appt-phone-color) 10px
        );
      `)}
`;

const EventBody = styled.span``;

const PatientIcon = styled.span`
  width: fit-content;
  padding: 5px;
`;

const PatientTitle = styled.span`
  width: fit-content;
`;

const ApptTypeIcon = styled.span`
  position: absolute;
  padding-right: 5px;
  right: 0;
`;

const WeekEvent = ({ Name, StartTime, EndTime, d }) => {
  return (
    <EventCard type={"phone"} start={StartTime} end={EndTime} duration={d}>
      <EventBody>
        <PatientIcon>
          <FaIcons.FaUser />
        </PatientIcon>
        <PatientTitle>{Name}</PatientTitle>
        {/* <ApptTypeIcon>
          {e.type === "video" ? (
            <FaIcons.FaVideo style={{ color: "#C38C00" }} />
          ) : e.type === "phone" ? (
            <FaIcons.FaPhone style={{ color: "green" }} />
          ) : (
            ""
          )}
        </ApptTypeIcon> */}
      </EventBody>
    </EventCard>
  );
};

export default WeekEvent;
