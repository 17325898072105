import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { timeIntervalData } from "../../model/data";
import "./../style/NewStyleSheet.css";

const WeekScheduler = () => {
  const [templateuse, setTemplateuse] = useState([]);
  // const [scheduledate, setScheduledate] = ({
  //   sdate:"",
  //   edate:"",
  //   provider_no:"999998",
  //   available:"1",
  //   priority: "b",
  //   hour: "",
  //   creator: "healthsmart",
  //   status: "A"
  // });

  const [scheduledate, setScheduledate] = useState({
    sdate:"",
    edate:"",
    provider_no:"",
    available:"1",
    priority: "b",
    hour: "",
    creator: "healthsmart",
    status: "A"

  })
  const [provider,setProvider]=useState([]);


  useEffect(() => {
    function getTempleteCode() {
      var axios = require("axios");

      var config = {
        method: "post",
        url: "https://testcf-boisterous-ardvark-pc.mybluemix.net/test/getScheduletemplate",
      };

      axios(config)
        .then(function (response) {
          setTemplateuse(...templateuse, response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    getTempleteCode();
  }, []);
  useEffect(() => {
    function getprovider() {
      var axios = require("axios");
      var parseString = require('xml2js').parseString;



      var config = {
        method: 'get',
        url: 'https://testcf-boisterous-ardvark-pc.mybluemix.net/test/providers',

      };
      
      axios(config)
      .then(function (response) {
        let providertemp=[];
        parseString(response.data, function (err, result) {
          let providerInfo=result.List.Item;
          for(let i=0;i<providerInfo.length;i++){
            console.log(providerInfo[i]["providerNo"])
            providertemp.push({"provider_no":providerInfo[i].providerNo[0],"provider_name":providerInfo[i].lastName+","+providerInfo[i].firstName})
          }
          console.log(providertemp);
          setProvider(...provider,providertemp)
      });
      })
      .catch(function (error) {
        console.log(error);
      });
    }
    getprovider();
  }, []);

  function submitSchedule(e) {
    e.preventDefault();
    var axios = require("axios");
    var qs = require("qs");
    var data = qs.stringify({
      'sdate': scheduledate.sdate,
      'edate': scheduledate.edate,
      'provider_no': scheduledate.provider_no,
      'available': '1',
      'priority': 'b',
      'hour': scheduledate.hour,
      'creator': 'healthsmart',
      'status': 'A' 
    });
    var config = {
      method: "post",
      url: "https://testcf-boisterous-ardvark-pc.mybluemix.net/test/addScheduledate",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        window.alert("You added a new Schedule!");
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  return (
    <div className="dashboard">
      <div className="main-body form-80-left">
        <p className="main-header-text-01">Lets get you set up with a</p>
        <p className="main-header-text-02">Week Scheduler</p>
        <p className="main-header-text-03">
          Enter the type of appointments that you would like to allow during
          different time blocks in your day
        </p>
        <div>
        Provider Number:
        <select name="time-slot-interval-select"
        value={scheduledate.provider_no} onChange={(e)=>{setScheduledate({...scheduledate,provider_no:e.target.value})}}>
        
          {provider.map((item,i) => {
            return (
              <option value={item.provider_no} key={i}>
                {item.provider_no} | {item.provider_name}
              </option>
            );
          })}
        </select>


      </div>
        <form className="main-form">
          <div className="main-form-field">
            Date from:
            <input type="date" value={scheduledate.sdate} onChange={(e)=>{setScheduledate({...scheduledate,sdate:e.target.value})}}/>
          </div>
          
          <div className="main-form-field">
            Date to:
            <input type="date" value={scheduledate.edate} onChange={(e)=>{setScheduledate({...scheduledate,edate:e.target.value})}}/>
          </div>
          Provider
          <table className="week-scheduler-table">
            <tr>
              <td>
                <p>is available every day of week?</p>
                <input type="checkbox" />
                <label>Alternating Week Setting</label>
              </td>
            </tr>
            <p>Select the day of the week which you are available:</p>
            <tr>
              <td>
                <input type="checkbox" />
                <label>Sunday</label>
              </td>
              <td>
                Template Using:
                <select name="time-slot-interval-select">
                  {templateuse.map((item) => {
                    return (
                      <option value={item.name} key={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <input type="checkbox" />
                <label>Monday</label>
              </td>
              <td>
                Template Using:
                <select name="time-slot-interval-select"
                value={scheduledate.hour} onChange={(e)=>{setScheduledate({...scheduledate,hour:e.target.value})}}>
                <option > </option>

                  {templateuse.map((item) => {
                    return (
                      <option value={item.name} key={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <input type="checkbox" />
                <label>Tuesday</label>
              </td>
              <td>
                Template Using:
                <select name="time-slot-interval-select">
                  {templateuse.map((item) => {
                    return (
                      <option value={item.name} key={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <input type="checkbox" />
                <label>Wednesday</label>
              </td>
              <td>
                Template Using:
                <select name="time-slot-interval-select">
                  {templateuse.map((item) => {
                    return (
                      <option value={item.name} key={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <input type="checkbox" />
                <label>Thursday</label>
              </td>
              <td>
                Template Using:
                <select name="time-slot-interval-select">
                  {templateuse.map((item) => {
                    return (
                      <option value={item.name} key={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <input type="checkbox" />
                <label>Friday</label>
              </td>
              <td>
                Template Using:
                <select name="time-slot-interval-select">
                  {templateuse.map((item) => {
                    return (
                      <option value={item.name} key={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <input type="checkbox" />
                <label>Saturday</label>
              </td>
              <td>
                Template Using:
                <select name="time-slot-interval-select">
                  {templateuse.map((item) => {
                    return (
                      <option value={item.name} key={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <input
                  type="button"
                  value="Cancel"
                  className="submit-secondary"
                />
              </td>
              <td>


                <button className="submit-primary" onClick={submitSchedule}>
                Submit
              </button>
              </td>
            </tr>
          </table>
          <br />
        </form>
      </div>

      <div className="dash-body cal-right"></div>
    </div>
  );
};

export default withRouter(WeekScheduler);
