import React from "react";
import { Route, Switch, Redirect } from "react-router";
import Navbar from "../components/Navbar/Navbar";
import Dashboard from "./Dashboard";
import Doctor from "./Calendar/DoctorView/Doctor";
import Settings from "./Settings";
import Clinic from "./Calendar/ClinicView/Clinic";
import Group from "./Calendar/GroupView/Group";
import Preference1 from "./PreferenceView/Preference1";
import Preference2 from "./PreferenceView/Preference2";
import AppointmentBooking from "./AppointmentSettings/AppointmentBooking";
import AppointmentTypes from "./AppointmentSettings/AppointmentTypes";
import ApptTimeSlots from "./AppointmentSettings/ApptTimeSlots";
import WeekScheduler from "./AppointmentSettings/WeekScheduler";

const Home = () => {
  return (
    <>
      <Navbar />
      <Switch>
        <Route exact path="/home">
          <Redirect to={{ pathname: "/home/dashboard" }} />
        </Route>
        <Route exact path="/home/dashboard" component={Dashboard} />
        <Route exact path="/home/preference1" component={Preference1} />
        <Route exact path="/home/preference2" component={Preference2} />
        <Route exact path="/home/booking" component={AppointmentBooking} />
        <Route exact path="/home/appttype" component={AppointmentTypes} />
        <Route exact path="/home/appttimeslots" component={ApptTimeSlots} />
        <Route exact path="/home/weekscheduler" component={WeekScheduler} />

        <Route exact path="/home/calendar/doctor" component={Doctor} />
        <Route exact path="/home/calendar/clinic" component={Clinic} />
        <Route exact path="/home/calendar/group" component={Group} />
        <Route exact path="/home/settings" component={Settings} />
      </Switch>
    </>
  );
};

export default Home;
