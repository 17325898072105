import React, { useState } from "react";
import * as AiIcons from "react-icons/ai";
import * as FiIcon from "react-icons/fi";
import * as BiIcons from "react-icons/bi";
import "./SideBar.css";

const SideBar = ({ activeState, setActiveState }) => {
  const [sidePanel1, setSidePanel1] = useState(true);
  const [sidePanel2, setSidePanel2] = useState(false);
  const [sidePanel3, setSidePanel3] = useState(false);

  const handlePanelToggle = (sidePanel) => {
    switch (sidePanel) {
      case "sidePanel1":
        setSidePanel1(!sidePanel1);
        if (sidePanel1 === false) {
          setSidePanel2(false);
          setSidePanel3(false);
        }
        break;
      case "sidePanel2":
        setSidePanel2(!sidePanel2);
        if (sidePanel2 === false) {
          setSidePanel1(false);
          setSidePanel3(false);
        }
        break;
      case "sidePanel3":
        setSidePanel3(!sidePanel3);
        if (sidePanel3 === false) {
          setSidePanel1(false);
          setSidePanel2(false);
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className={activeState ? "side-bar active" : "side-bar"}>
      <div className="sidebar-header">
        <div className="info-panel-title">
          <p className="main-header-text-01">Lets get you set up</p>
          <p className="main-header-text-02">Appointment Booking</p>
        </div>
        <div
          className="close-button"
          onClick={() => setActiveState(!activeState)}
        >
          <AiIcons.AiOutlineClose size={14} />
        </div>
      </div>

      <div className="search-box">
        <input
          className="search-bar"
          type="text"
          placeholder="Search Patients, Practitioners,.."
        />
        <BiIcons.BiSearchAlt size={22} />
      </div>

      <div className="sidebar-form">
        <form method="post" className="sidebar-panel-form">
          <div className="sidebar-panel-handle">
            <span className="sidebar-panel-handle-number">01</span>
            <span className="sidebar-panel-handle-container">
              <span>Booking Schedule</span>
              <span
                className="sidebar-panel-handle-chevron"
                onClick={() => handlePanelToggle("sidePanel1")}
              >
                {sidePanel1 ? <FiIcon.FiChevronUp /> : <FiIcon.FiChevronDown />}
              </span>
            </span>
          </div>
          <span
            className={sidePanel1 ? "sidebar-panel active" : "sidebar-panel"}
          >
            <div>
              Date*
              <input type="date" name="date" />
            </div>
            <br />
            <div>
              Start Time*
              <input type="time" name="time" />
            </div>
            <br />
            <div>
              Duration*
              <select name="duration">
                <option value="none">Please Select</option>
                <option value="15">15mins</option>
                <option value="30">30mins</option>
                <option value="45">45mins</option>
              </select>
            </div>
            <br />
            <div>
              Clinic Location*
              <select name="location">
                <option value="none">Please Select</option>
                <option value="1">Location 1</option>
                <option value="2">Location 2</option>
                <option value="3">Location 3</option>
              </select>
            </div>
            <br />
          </span>

          <div className="sidebar-panel-handle">
            <span className="sidebar-panel-handle-number">02</span>
            <span className="sidebar-panel-handle-container">
              <span>Booking Type</span>
              <span
                className="sidebar-panel-handle-chevron"
                onClick={() => handlePanelToggle("sidePanel2")}
              >
                {sidePanel2 ? <FiIcon.FiChevronUp /> : <FiIcon.FiChevronDown />}
              </span>
            </span>
          </div>
          <span
            className={sidePanel2 ? "sidebar-panel active" : "sidebar-panel"}
          >
            <div>
              Status*
              <select name="location">
                <option value="none">Please Select</option>
                <option value="1">Booked</option>
                <option value="2">Pending</option>
              </select>
            </div>
            <br />
            <div>
              Appointment Type*
              <select name="type">
                <option value="none">Please Select</option>
                <option value="CDM">Chronical Diseases Management</option>
                <option value="CPE">Complete Physical Exam</option>
                <option value="PAP">PAP Exam</option>
                <option value="IME">Immigration Medical Exam</option>
                <option value="MH">Mental Health</option>
                <option value="MVA">Motor Vehicle Accident (ICBC)</option>
                <option value="WCB">Worker's Compensation (WCB)</option>
                <option value="CC">Complex Care</option>
                <option value="ON">Immunization</option>
              </select>
            </div>
            <br />
            <div>
              Provider Number*
              <input type="text" name="provider" />
            </div>
            <br />
          </span>

          <div className="sidebar-panel-handle">
            <span className="sidebar-panel-handle-number">03</span>
            <span className="sidebar-panel-handle-container">
              <span>Patient Details</span>
              <span
                className="sidebar-panel-handle-chevron"
                onClick={() => handlePanelToggle("sidePanel3")}
              >
                {sidePanel3 ? <FiIcon.FiChevronUp /> : <FiIcon.FiChevronDown />}
              </span>
            </span>
          </div>
          <span
            className={sidePanel3 ? "sidebar-panel active" : "sidebar-panel"}
          >
            <div>
              First Name
              <input type="text" name="firstname" />
            </div>
            <br />
            <div>
              Last Name
              <input type="text" name="lastname" />
            </div>
            <br />
            <div>
              Patient ID*
              <input type="text" name="patientid" required />
            </div>
            <br />
            <div>
              Date of Birth
              <input type="date" name="dob" />
            </div>
            <br />
            <div>
              Mobile
              <input type="text" name="phone" />
            </div>
            <br />
            <div>
              Email
              <input type="text" name="email" />
            </div>
            <br />
            <div>
              Address
              <input type="text" name="address" />
            </div>
            <br />
            <div>
              Message to Patient <br />
              <textarea rows="4" cols="50" name="message">
                Please book an appointment within next 3 weeks
              </textarea>
            </div>
            <br />
          </span>
          <span className="sidebar-submit-buttons">
            <button
              className="submit-secondary"
              onClick={() => setActiveState(!activeState)}
            >
              Close
            </button>
            <button className="submit-primary">Submit</button>
          </span>
        </form>
      </div>
    </div>
  );
};

export default SideBar;
