import React from 'react'
import { withRouter } from 'react-router'

const Group = () => {
  return (
    <div className="dashboard">
      <div className="dash-body cal-left">
        <h2 className="centered">Group View</h2>
      </div>
      <div className="dash-body cal-right">
        <h2 className="centered">Side Panel</h2>
        <div></div>
      </div>
    </div>
  );
}

export default withRouter(Group);
