import { ActionTypes } from "../constants/actionTypes";

const initialState = {
  monthEvents: []
};

const monthCalendarReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_MONTH_CALENDAR_DATA:
      return { ...state, monthEvents: payload };

    default:
      return state;
  }
};

export default monthCalendarReducers;
