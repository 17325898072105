import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setMonthCalendarData } from "./../../redux/actions/calendarActions";
import ReminderEvent from "../HS-Events/ReminderEvent";
import MonthEvent from "../HS-Events/MonthEvent";
import * as dateFns from "date-fns";
import axios from "axios";
import qs from "qs";

const Calendar = (props) => {
  const [currentMonth, setCurrentMonth] = useState(props.currentDate);
  const [selectedDate] = useState(new Date());
  const events = useSelector((state) => state.monthCalendarData.monthEvents);
  const reminders = useSelector((state) => state.reminderData.reminderEvents);
  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentMonth(props.currentDate);
  }, [props.currentDate]);

  useEffect(() => {
    fetchEventsAPI();
  }, [currentMonth]);

  const fetchEventsAPI = async () => {
    const data = qs.stringify({
      practitionerid: "999998",
      startday: dateFns.format(
        dateFns.startOfMonth(currentMonth),
        "yyyy-MM-dd"
      ),
      endday: dateFns.format(
        dateFns.endOfMonth(currentMonth),
        "yyyy-MM-dd"),
    });

    const config = {
      method: "post",
      url: "https://testcf-boisterous-ardvark-pc.mybluemix.net/test/getMonthCalender",
      headers: { 
        'Content-Type': 'application/x-www-form-urlencoded', 
      },
      data: data,
    };

    await axios(config)
      .then(function (response) {
        console.log(data);
        console.log(response.data)
        dispatch(setMonthCalendarData(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const renderDays = () => {
    const dateFormat = "EEEE";
    const days = [];

    let startDate = dateFns.startOfWeek(currentMonth);

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {dateFns.format(dateFns.addDays(startDate, i), dateFormat)}
        </div>
      );
    }

    return <div className="days row">{days}</div>;
  };

  const renderCells = () => {
    const monthStart = dateFns.startOfMonth(currentMonth);
    const monthEnd = dateFns.endOfMonth(monthStart);
    const startDate = dateFns.startOfWeek(monthStart);
    const endDate = dateFns.endOfWeek(monthEnd);

    const dateFormat = "dd";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    // let style = {
    //   backgroundColor: "rgba(119, 119, 119, 0)",
    // };

    // let reminders = [
    //   {
    //     type: "month-reminder",
    //     start: new Date(2021, 10, 12),
    //     icon: (
    //       <Popup
    //         trigger={<FaIcons.FaBell />}
    //         content={
    //           <div className="card">
    //             <div className="card-title">
    //               <span>Reminder</span>
    //             </div>
    //             <div className="card-body">
    //               <span>This is static reminder!</span>
    //             </div>
    //           </div>
    //         }
    //         position="top left"
    //         style={style}
    //       />
    //     ),
    //   },
    // ];

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = dateFns.format(day, dateFormat);
        days.push(
          <div
            className={`col cell ${
              !dateFns.isSameMonth(day, monthStart)
                ? "disabled"
                : dateFns.isSameDay(day, selectedDate)
                ? "selected"
                : ""
            }`}
            key={day}
            //   onClick={() => this.onDateClick(dateFns.parse(cloneDay))}
          >
            <span className="number">{formattedDate}</span>
            <span className="bg">{formattedDate}</span>
            {renderReminder(day)}
            {renderEvent(day)}
          </div>
        );
        day = dateFns.addDays(day, 1);
      }
      rows.push(
        <div className="row" key={day}>
          {days}
        </div>
      );
      days = [];
    }

    return <div className="body">{rows}</div>;
  };

  const renderEvent = (day) => {
    return events.length > 0 ? (
      events.map((event) => {
        const { id, date, startTime, endTime, appointmentNumber } = event;
        return (
          <div>
            {dateFns.format(day, "yyyy-MM-dd") === date && (
              <MonthEvent
                id={id}
                startTime={new Date(startTime)}
                endTime={new Date(endTime)}
                apNum={appointmentNumber}
              />
            )}
          </div>
        );
      })
    ) : (
      <></>
    );
  };

  const renderReminder = (day) => {
    return reminders.length > 0 ? (
      reminders.map((reminder) => {
        const { date, contents } = reminder;
        return (
          <div className="reminder-icon">
            {dateFns.format(day, "ddMMyyyy") ===
              dateFns.format(new Date(date), "ddMMyyyy") && (
              <div className="month-reminder">
                <ReminderEvent 
                  content={contents}/>
              </div>
            )}
          </div>
        );
      })
    ) : (
      <></>
    );
  };

  return (
    <div className="calendar">
      <div className="calendar-body">
        {renderDays()}
        {renderCells()}
      </div>
    </div>
  );
};

export default Calendar;
