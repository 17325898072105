import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const SidebarLink = styled(Link)`
  margin-left: 0;
  padding-left: 1.25rem;
  font-size: 1.25rem;
  color: #004225;
  height: 48px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:hover {
    background: #d5d5d5;
    cursor: pointer;
  }
`;

const SubMenu = ({ item }) => {
  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);

  return (
    <>
      <SidebarLink to={item.path} onClick={item.subNav && showSubnav}>
        <div>{item.icon}</div>
      </SidebarLink>
      {subnav && item.subNav.map((item, index) => {
          return (
              <SidebarLink to={item.path} key={index}>
                  {item.icon}
              </SidebarLink>
          )
      })}
    </>
  );
};

export default SubMenu;
