import { ActionTypes } from "../constants/actionTypes";

const initialState = {
  weekEvents: []
};

const weekCalendarReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_WEEK_CALENDAR_DATA:
      return {...state, weekEvents:payload};

    default:
      return state;
  }
};

export default weekCalendarReducers;
