import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import DCalendar from "../../../components/HS-Calendar/DCalendar";
import DyCalendar from "../../../components/HS-Calendar/DyCalendar";
import * as dateFns from "date-fns";
import { setCurrentApptDateTime } from "./../../../redux/actions/calendarActions";

import { VisitHistoryData } from "../../../model/data";
import { MedicalHistoryData } from "../../../model/data";
import { MdOutlineMedicalServices } from "react-icons/md";
import HSCard from "../../../components/HS-Card/HSCard";
import SideBar from "../../../components/SideBar/SideBar";

const DayView = () => {
  const [date, setDate] = useState(new Date());
  const [activeState, setActiveState] = useState(false);
  const events = useSelector((state) => state.dayCalendarData.dayEvents);

  const apptInfo = useSelector(
    (state) => state.currentApptDateTime.currentApptDateTime
  );

  const dispatch = useDispatch();

  const onPrev = () => {
    setDate(dateFns.subDays(date, 1));
  };

  const onNext = () => {
    setDate(dateFns.addDays(date, 1));
  };

  useEffect(() => {
    dispatch(setCurrentApptDateTime([]));
  }, [date]);

  return (
    <div className="dashboard">
      <div className="dash-body doc-day-left">
        <div className="header-left">
          <h3 className="date-title">
            {dateFns.format(date, "dd MMMM, yyyy")}
          </h3>
        </div>
        <DyCalendar currentDate={date} onPrev={onPrev} onNext={onNext} />
      </div>

      <div className="dash-body doc-day-middle">
        <div className="header-middle">
          <div className="info-panel-title">
            <p className="main-header-text-01">Learn with the patient</p>
            <p className="main-header-text-02">Information Panel</p>
          </div>
          <button
            className="action-primary"
            onClick={() => setActiveState(true)}
          >
            + Add Event
          </button>
          {/* <div className="calendar-dropdown">
            <select className="calendar-select">
              <option vlaue="IMCVancouver">IMC Vancouver, BC</option>
              <option vlaue="IMCMississuaga">IMC Mississauga, ON</option>
              <option vlaue="IMCToronto">IMC Toronto, ON</option>
            </select>
          </div> */}
        </div>

        {/* Use the below logic with real data */}
        {/* <div className="info-panel-primary-card">
          <div className="info-card-title">
            {apptInfo.length > 0
              ? "Patient Info:"
              : "Select an Appointment to view Information"}
          </div>
          {apptInfo.length > 0 ? (
            <>
              <div className="info-card-subtitle">
                {apptInfo[0]["Patient Name"]}
              </div>
              <div className="info-card-subtitle">
                {apptInfo[0]["patientInfo"]["birthday"]}
              </div>
              <div className="info-card-subtitle">
                {apptInfo[0]["patientInfo"]["gender"]}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        {apptInfo.length > 0 ? (
          <div className="info-panel-primary-card">
            <div className="info-card-title">Appointment Info:</div>
            <div className="info-card-subtitle">Reason for Visit:</div>
            <div className="info-card-body">
              {apptInfo[0]["Reason(Condition) To Visit"]}
            </div>
            <div className="info-card-subtitle">Appointment Desc:</div>
            <div className="info-card-body">
              {apptInfo[0]["Appointment Description"]}
            </div>
            <div className="info-card-subtitle">Doctor Comment:</div>
            <div className="info-card-body">
              {apptInfo[0]["Doctor Comment"]}
            </div>
          </div>
        ) : (
          <></>
        )} */}
        <div className="info-panel-main-section">
          <div className="info-panel-section">
            <label className="info-panel-section-label">Visit Type</label>
            <div>
              <label className="info-panel-section-sub-label">
                Appointment Type
              </label>
              <select>
                <option value="GA">General Appointment</option>
                <option value="2">Appointment Type 2</option>
                <option value="3">Appointment Type 3</option>
              </select>
            </div>
            <div>
              <label className="info-panel-section-sub-label">Status</label>
              <select>
                <option value="WR">Waiting Room</option>
                <option value="2">Status Type 2</option>
                <option value="3">Status Type 3</option>
              </select>
            </div>
            <div>
              <label className="info-panel-section-sub-label">
                Doctor Name
              </label>
              <input type="text" placeholder="Doctor Name" />
            </div>
          </div>
          <div className="info-panel-section">
            <label className="info-panel-section-label">Demographics</label>
            <div>
              <label className="info-panel-section-sub-label">
                Languages Spoken
              </label>
              <select>
                <option value="Eng">English</option>
                <option value="FRN">French</option>
                <option value="SPN">Spanish</option>
              </select>
            </div>
            <div>
              <label className="info-panel-section-sub-label">Mobility</label>
              <select>
                <option value="WR">Wheelchair</option>
                <option value="2">Status Type 2</option>
                <option value="3">Status Type 3</option>
              </select>
            </div>
            <div>
              <label className="info-panel-section-sub-label">
                Consultation Notes
              </label>
              <textarea type="text" placeholder="Brief description" />
            </div>
          </div>
          <div className="info-panel-section-card">
            <label className="info-panel-section-card-label">
              Visit History
            </label>
            <hr />
            {VisitHistoryData.map((data) => {
              const { id, type, name, date } = data;
              return (
                <div className="section-card-container" key={id}>
                  <div className="section-card-icon-container">
                    <MdOutlineMedicalServices size={15} />
                  </div>
                  <div className="section-card-container-full">
                    <div className="section-card-title-1">Appointment</div>
                    <div className="section-card-subtitle-1">{type}</div>
                  </div>
                  <div className="section-card-container-half">
                    <div className="section-card-title-2">Doctor Name</div>
                    <div className="section-card-subtitle-2">{name}</div>
                  </div>
                  <div className="section-card-container-half">
                    <div className="section-card-title-2">Date</div>
                    <div className="section-card-subtitle-2">{date}</div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="info-panel-section-card">
            <label className="info-panel-section-card-label">
              Medical History
            </label>
            <hr />
            {MedicalHistoryData.map((data) => {
              const { id, name, quantity, method, route, duration, refills } =
                data;
              return (
                <div className="section-card-container" key={id}>
                  <div className="section-card-icon-container">
                    <MdOutlineMedicalServices size={15} />
                  </div>
                  <div className="section-card-container-full">
                    <div className="section-card-title-1">Medication</div>
                    <div className="section-card-subtitle-1">
                      {name},{quantity}
                    </div>
                  </div>
                  <div className="section-card-container-half">
                    <div className="section-card-title-2">Method</div>
                    <div className="section-card-subtitle-2">{method}</div>
                  </div>
                  <div className="section-card-container-half">
                    <div className="section-card-title-2">Route</div>
                    <div className="section-card-subtitle-2">{route}</div>
                  </div>
                  <div className="section-card-container-half">
                    <div className="section-card-title-2">Duration</div>
                    <div className="section-card-subtitle-2">{duration}</div>
                  </div>
                  <div className="section-card-container-half">
                    <div className="section-card-title-2">Refills</div>
                    <div className="section-card-subtitle-2">{refills}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="dash-body doc-day-right">
        <DCalendar currentDate={date} onPrev={onPrev} onNext={onNext} />
        <SideBar activeState={activeState} setActiveState={setActiveState} />
        <div className="card">
          <div className="card-title">
            <span>Summary</span>
          </div>
          <div className="card-body">
            <span>
              Patients Booked: {events.length > 0 ? events.length : "N/A"}
            </span>
            <br />
            <span>Total Slots Available: N/A</span>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DayView;
