import React from 'react'
import { withRouter } from 'react-router'
import './style/Dashboard.css'
import 'react-calendar/dist/Calendar.css';

const Dashboard = () => {
  return (
    <div className="dashboard">
      <div className="dash-body cal-left">
        <div className="centered">
          <h2>Dashboard</h2>
          <span>Coming soon...</span>
        </div>
      </div>
      <div className="dash-body cal-right">
        <h2 className="centered">Notification</h2>
        <div></div>
      </div>
    </div>
  );
}

export default withRouter(Dashboard);
