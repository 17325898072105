import React, { useState } from "react";
import "../style/CreatingProfile.css";
import "./../style/NewStyleSheet.css";

import HSLogo from "../../res/images/hs_logo_02.png";
import WelcomeImg from "../../res/images/login_img_04.png";
import { FiMapPin } from "react-icons/fi";

// import axios from "axios";
// import qs from "qs";
// import EmailValidator from "email-validator";
// import Phone from "../../res/images/phone.png";

const AddressDetails = () => {
  //create 2 states to store name
  // const [newfName, setNewfName] = useState("");
  // const [newlName, setNewlName] = useState("");
  // const [username, setNewusername] = useState("");
  // const [password, setNewpass] = useState("");
  // const [confirmpassword, setNewcpass] = useState("");

  // function handleChangef(e) {
  //   setNewfName(e.target.value);
  // }
  // function handleChangel(e) {
  //   setNewlName(e.target.value);
  // }
  // function handleChangeusername(e) {
  //   setNewusername(e.target.value);
  // }
  // function handleChangepass(e) {
  //   setNewpass(e.target.value);
  // }
  // function handleChangeCpass(e) {
  //   setNewcpass(e.target.value);
  // }

  // //create submission function, subit to fhir server
  // function handleSubmit(e) {
  //   e.preventDefault();
  //   window.location = "/profile/step4";
  // }

  return (
    // <div className="container-center-horizontal">
    //   <div className="hs-desk-create-account screen">
    //     <div className="flex-col">
    //       <img className="ident" src={Phone} />
    //       <h1 className="title">Verify your Mobile Number</h1>

    //       <div>
    //         <form onSubmit={handleSubmit}>
    //           <div className="overlap-group7 border-1-5px-heavy-metal">
    //             <input
    //               className="roboto-light-chicago-20px bordertype"
    //               type="text"
    //               value={newfName}
    //               placeholder="XXX-XXX-XXXX"
    //               onChange={handleChangef}
    //             />{" "}
    //             <button className="sendotp">Send OTP</button>
    //           </div>

    //           <div className="overlap-group10 border-1-5px-heavy-metal">
    //             <input
    //               className="roboto-light-chicago-20px bordertype"
    //               type="text"
    //               placeholder="  _"
    //               onChange={handleChangel}
    //             />
    //           </div>
    //           <div className="overlap-group10 border-1-5px-heavy-metal">
    //             <input
    //               className="roboto-light-chicago-20px bordertype"
    //               type="text"
    //               placeholder="  _"
    //               onChange={handleChangel}
    //             />
    //           </div>
    //           <div className="overlap-group10 border-1-5px-heavy-metal">
    //             <input
    //               className="roboto-light-chicago-20px bordertype"
    //               type="text"
    //               placeholder="  _"
    //               onChange={handleChangel}
    //             />
    //           </div>

    //           <div className="overlap-group10 border-1-5px-heavy-metal">
    //             <input
    //               className="roboto-light-chicago-20px bordertype"
    //               type="text"
    //               placeholder="  _"
    //               onChange={handleChangel}
    //             />
    //           </div>
    //           <div className="overlap-group10 border-1-5px-heavy-metal">
    //             <input
    //               className="roboto-light-chicago-20px bordertype"
    //               type="text"
    //               placeholder="  _"
    //               onChange={handleChangel}
    //             />
    //           </div>
    //           <div className="overlap-group10 border-1-5px-heavy-metal">
    //             <input
    //               className="roboto-light-chicago-20px bordertype"
    //               type="text"
    //               placeholder="  _"
    //               onChange={handleChangel}
    //             />
    //           </div>

    //           <button className="overlap-group2">Verify</button>
    //         </form>
    //       </div>
    //     </div>
    //     <div className="side-panel">
    //       <div className="logohealthsmartstatement">
    //         <img
    //           className="logo"
    //           src="https://anima-uploads.s3.amazonaws.com/projects/61797edcbbae245115aa994a/releases/617981f2aeaa14544428f197/img/logo@1x.png"
    //         />
    //         <div className="text-1 valign-text-middle">
    //           ASSISTED HEALTHCARE INTELLIGENCE
    //         </div>
    //       </div>
    //       <div className="overlap-group1">
    //         <div className="overlap-group-1">
    //           <div className="image-3">
    //             <img
    //               className="screenshot-2021-03-12-at-2102-1"
    //               src="https://anima-uploads.s3.amazonaws.com/projects/61797edcbbae245115aa994a/releases/617981f2aeaa14544428f197/img/screenshot-2021-03-12-at-21-02-1@2x.png"
    //             />
    //           </div>
    //           <div className="image-2">
    //             <img
    //               className="screenshot-2021-03-12-at-2104-1"
    //               src="https://anima-uploads.s3.amazonaws.com/projects/61797edcbbae245115aa994a/releases/617981f2aeaa14544428f197/img/screenshot-2021-03-12-at-21-04-1@2x.png"
    //             />
    //           </div>
    //         </div>
    //         <div className="image-1">
    //           <img
    //             className="screenshot-2021-03-12-at-2103-1"
    //             src="https://anima-uploads.s3.amazonaws.com/projects/61797edcbbae245115aa994a/releases/617981f2aeaa14544428f197/img/screenshot-2021-03-12-at-21-03-1@2x.png"
    //           />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    /* New Code Starts here ... */
    <div className="login-panel">
      <div className="login-left-panel">
        <div className="panel-holder">
          <img src={HSLogo} alt="HS_Logo" className="hs-logo-img" />
          <h2>Enter your details</h2>
          <h2>to help us serve</h2>
          <h2>you better</h2>
          <img src={WelcomeImg} alt="HS_Logo" className="hs-welcome-img-2" />
        </div>
      </div>
      <div className="login-right-panel">
        <div className="panel-holder">
          <p className="progress-bar-text">
            <strong>80%</strong> Update in progress
          </p>
          <hr id="hr01" />
          <hr className="login-address-hr" id="hr02" />
          <div className="login-header-01">
            <div className="login-header-icon">
              <FiMapPin size={28} />
            </div>
            <p className="login-header-text-01">Residence</p>
            <p className="login-header-text-02">Your Address</p>
          </div>
          <form action="/profile/step5">
            <div>
              Address line 1 <br />
              <input type="text" />
            </div>
            <br />
            <div>
              Address line 2 <br />
              <input type="text" />
            </div>
            <br />
            <div>
              City <br />
              <input type="text" />
            </div>
            <br />
            <div>
              Province <br />
              <select name="province">
                {/* <option value="AB">Alberta</option> */}
                <option value="BC">British Columbia</option>
                {/* <option value="MB">Manitoba</option>
                <option value="NB">New Brunswick</option>
                <option value="NL">Newfoundland and Labrador</option>
                <option value="NS">Nova Scotia</option>
                <option value="NT">Northwest Territories</option>
                <option value="NU">Nunavut</option> */}
                <option value="ON">Ontario</option>
                {/* <option value="PE">Prince Edward Island</option>
                <option value="QC">Quebec</option>
                <option value="SK">Saskatchewan</option>
                <option value="YT">Yukon</option> */}
              </select>
            </div>
            <br />
            <div>
              Postal/Zip Code <br />
              <input type="text" />
            </div>
            <br />
            <div>
              Country <br />
              <select name="country">
                <option value="CA">Canada</option>
                <option value="US">United States</option>
                <option value="UK">United Kingdom</option>
              </select>
            </div>
            <br />
            <input
              type="button"
              value="Save Progress"
              className="submit-secondary"
            />
            <input type="submit" value="Next" className="submit-primary" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddressDetails;
