import axios from 'axios';
import qs from 'qs';
import React, { useState } from "react";
import '../style/CreatingProfile.css'
import EmailValidator from 'email-validator';


export default function Preference1() {

  const [specialty, setspecialty] = useState('');
  const [languages, setlanguages] = useState('');
  const [credentials, setcredentials] = useState('');
  const [professionalbio, setprofessionalbio] = useState('');
  const [bioforpatient, setNewbioforpatient] = useState('');


  function handlechangespecialty(e) {
    setspecialty(e.target.value);
  }
  function handleLanguages(e) {
    setlanguages(e.target.value);
  }
  function handlecredentials(e) {
    setcredentials(e.target.value);
  }
  function handleprofessionalbio(e) {
    setprofessionalbio(e.target.value);
  }
  function handleBioforpatient(e) {
    setNewbioforpatient(e.target.value);
  }

  //create submission function, subit to fhir server
  function handleSubmit(e) {
    e.preventDefault();

    var axios = require('axios');
var qs = require('qs');
var data = qs.stringify({
  'practitionerid': 3,
  'Specialty': specialty,
  'Languages': languages,
  'Credentials': credentials,
  'ProfessionalBio': professionalbio,
  'BioforPatients': bioforpatient 
});
var config = {
  method: 'post',
  url: 'http://localhost:4000/profile/createPPpreference_1',
  headers: { 
    'Authorization': 'Bearer 04347ef6920b5dc33c811a834f0138d33543ded5', 
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});
    window.location = "/home/preference2";

    console.log('hello')
    //   window.location = "/profile/step5";

  }
  const preferenceTemplete = (
    <div className="container-center-horizontal1">
      <div className="hs-desk-create-account screen">
        <div className="flex-col2">


          <div>
            <h1 className="title1">Professional Profile</h1>

            <form onSubmit={handleSubmit}>

              <div className="overlap-group_p border-1-5px-heavy-metal">
                <input className="roboto-light-chicago-20px bordertype" type="text" value={specialty}
                  placeholder="Specialty" onChange={handlechangespecialty} />
              </div>
              <div className="overlap-group_p border-1-5px-heavy-metal">
                <input className="roboto-light-chicago-20px bordertype" type="text" value={languages}
                  placeholder="Languages"
                  onChange={handleLanguages} />
              </div>


              <div className="overlap-group_p border-1-5px-heavy-metal">
                <input className="roboto-light-chicago-20px bordertype" type="text" value={credentials}
                  placeholder="Credentials"
                  onChange={handlecredentials} />
              </div>


              <div className="overlap-group_p_p border-1-5px-heavy-metal">
                <input className="roboto-light-chicago-20px bordertype" type="text" value={professionalbio}
                  placeholder="Professional  Bio (250 words)"
                  onChange={handleprofessionalbio} />

              </div>
              <h1 className="title1"> Patient Profile</h1>

              <div className="overlap-group_p_p border-1-5px-heavy-metal">
                <input className="roboto-light-chicago-20px bordertype" type="text" value={bioforpatient}
                  placeholder="Bio for Patients (180 words)"
                  onChange={handleBioforpatient} />

              </div>
              <h1 className="title1"> Clinic Info</h1>
              <div className="overlap-group_p border-1-5px-heavy-metal">
                <input className="roboto-light-chicago-20px bordertype" type="text" 
                // value={}
                  placeholder="Clinic Postal Code"
                  // onChange={}
                   />

              </div>
              <button className="overlap-group2_2_p">+ Add Billing Address</button>
              <button className="overlap-group2_2_p">+ Add Billing Number</button>
              <button className="overlap-group2_2_p">+ Add Billing Number</button>

              <button className="overlap-group2_p" to="/home/preference1">Next</button>
            </form>



          </div>

        </div>
        <div className="side-panel">


        </div>


      </div>

    </div>



  );

  return <div >{preferenceTemplete}</div>;
}