import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Calendar from "../../../components/HS-Calendar/Calendar";
import MCalendar from "../../../components/HS-Calendar/MCalendar";
import { setReminderData } from "../../../redux/actions/calendarActions";
import * as dateFns from "date-fns";
import axios from "axios";
import qs from "qs";
import SideBar from "../../../components/SideBar/SideBar";

const MonthView = () => {
  const [date, setDate] = useState(new Date());
  const [activeState, setActiveState] = useState(false);
  const events = useSelector((state) => state.monthCalendarData.monthEvents);
  const reminders = useSelector((state) => state.reminderData.reminderEvents);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchEventsAPI();
  }, []);

  const onPrev = () => {
    setDate(dateFns.subMonths(date, 1));
  };

  const onNext = () => {
    setDate(dateFns.addMonths(date, 1));
  };

  const fetchEventsAPI = async () => {
    const data = qs.stringify({
      userid: "3",
    });

    const config = {
      method: "post",
      url: "http://localhost:8080/month/getreminder",
      headers: {
        Authorization: "Bearer 04347ef6920b5dc33c811a834f0138d33543ded5",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    await axios(config)
      .then(function (response) {
        dispatch(setReminderData(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="dashboard">
      <div className="dash-body doc-week-left">
        <div className="doc-week-header">
          <h3 className="date-title">{dateFns.format(date, "MMMM yyyy")}</h3>
          <button
            className="action-primary"
            onClick={() => setActiveState(true)}
          >
            + Add Event
          </button>
          {/* <div className="calendar-dropdown">
            <select className="calendar-select">
              <option vlaue="IMCVancouver">IMC Vancouver, BC</option>
              <option vlaue="IMCMississuaga">IMC Mississauga, ON</option>
              <option vlaue="IMCToronto">IMC Toronto, ON</option>
            </select>
          </div> */}
        </div>
        <Calendar currentDate={date} />
      </div>
      <div className="dash-body doc-week-right">
        <MCalendar currentDate={date} onPrev={onPrev} onNext={onNext} />
        <SideBar activeState={activeState} setActiveState={setActiveState} />
        <div className="card">
          <div className="card-title">
            <span>Summary</span>
          </div>
          <div className="card-body">
            <span>
              {"Patients Booked : " +
                events.reduce((x, e) => (x = x + e.appointmentNumber), 0)}
            </span>
            <br />
            <span>Total Slots Available: N/A</span>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonthView;
