import React, { useState } from "react";
import "../style/CreatingProfile.css";
import "./../style/NewStyleSheet.css";

import HSLogo from "../../res/images/hs_logo_02.png";
import WelcomeImg from "../../res/images/login_img_01.png";
import { FaMobileAlt } from "react-icons/fa";

// import axios from "axios";
// import qs from "qs";
// import EmailValidator from "email-validator";
// import House from "../../res/images/house.png";
// import Minus from "../../res/images/minus.png";

const PersonalProfile = () => {
  // //create 2 states to store name
  // const [newfName, setNewfName] = useState("");
  // const [newlName, setNewlName] = useState("");
  // const [username, setNewusername] = useState("");
  // const [password, setNewpass] = useState("");
  // const [confirmpassword, setNewcpass] = useState("");

  // function handleChangef(e) {
  //   setNewfName(e.target.value);
  // }
  // function handleChangel(e) {
  //   setNewlName(e.target.value);
  // }
  // function handleChangeusername(e) {
  //   setNewusername(e.target.value);
  // }
  // function handleChangepass(e) {
  //   setNewpass(e.target.value);
  // }
  // function handleChangeCpass(e) {
  //   setNewcpass(e.target.value);
  // }

  // //create submission function, subit to fhir server
  // function handleSubmit(e) {
  //   e.preventDefault();
  //   window.location = "/profile/step5";
  // }

  return (
    // <div className="container-center-horizontal">
    //   <div className="hs-desk-create-account screen">
    //     <div className="flex-col1">
    //       <img className="ident1" />
    //       <h1 className="title">Profile Setting</h1>
    //       <div>
    //         <button className="overlap-group2_2">+ Upload Photo</button>
    //         <form>
    //           <div className="overlap-group7 border-1-5px-heavy-metal">
    //             <input
    //               className="roboto-light-chicago-20px bordertype"
    //               type="text"
    //               placeholder="Prefered Name"
    //             />
    //           </div>
    //           <div className="overlap-group7 border-1-5px-heavy-metal">
    //             <input
    //               className="roboto-light-chicago-20px bordertype"
    //               type="text"
    //               placeholder="Prefered Gender"
    //             />
    //           </div>
    //           <div className="overlap-group7 border-1-5px-heavy-metal">
    //             <input
    //               className="roboto-light-chicago-20px bordertype"
    //               type="text"
    //               placeholder="Emergency Contact Name"
    //             />
    //           </div>
    //           <div className="overlap-group7 border-1-5px-heavy-metal">
    //             <input
    //               className="roboto-light-chicago-20px bordertype"
    //               type="text"
    //               placeholder="Emergency Contact Number"
    //             />
    //           </div>
    //           <button className="overlap-group2">Save Preferences</button>
    //         </form>
    //       </div>
    //     </div>
    //     <div className="side-panel">
    //       <div className="logohealthsmartstatement">
    //         <img
    //           className="logo"
    //           src="https://anima-uploads.s3.amazonaws.com/projects/61797edcbbae245115aa994a/releases/617981f2aeaa14544428f197/img/logo@1x.png"
    //         />
    //         <div className="text-1 valign-text-middle">
    //           ASSISTED HEALTHCARE INTELLIGENCE
    //         </div>
    //       </div>
    //       <div className="overlap-group1">
    //         <div className="overlap-group-1">
    //           <div className="image-3">
    //             <img
    //               className="screenshot-2021-03-12-at-2102-1"
    //               src="https://anima-uploads.s3.amazonaws.com/projects/61797edcbbae245115aa994a/releases/617981f2aeaa14544428f197/img/screenshot-2021-03-12-at-21-02-1@2x.png"
    //             />
    //           </div>
    //           <div className="image-2">
    //             <img
    //               className="screenshot-2021-03-12-at-2104-1"
    //               src="https://anima-uploads.s3.amazonaws.com/projects/61797edcbbae245115aa994a/releases/617981f2aeaa14544428f197/img/screenshot-2021-03-12-at-21-04-1@2x.png"
    //             />
    //           </div>
    //         </div>
    //         <div className="image-1">
    //           <img
    //             className="screenshot-2021-03-12-at-2103-1"
    //             src="https://anima-uploads.s3.amazonaws.com/projects/61797edcbbae245115aa994a/releases/617981f2aeaa14544428f197/img/screenshot-2021-03-12-at-21-03-1@2x.png"
    //           />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    /* New Code Starts here ... */
    <div className="login-panel">
      <div className="login-left-panel">
        <div className="panel-holder">
          <img src={HSLogo} alt="HS_Logo" className="hs-logo-img" />
          <h2>Your place to work</h2>
          <h2>Plan. Create. Control.</h2>
          <img src={WelcomeImg} alt="HS_Logo" className="hs-welcome-img" />
        </div>
      </div>
      <div className="login-right-panel">
        <div className="panel-holder">
          <p className="progress-bar-text">
            <strong>20%</strong> Update in progress
          </p>
          <hr id="hr01" />
          <hr id="hr02" className="login-profile-hr" />
          <div className="login-header-01">
            <div className="login-header-icon">
              <FaMobileAlt size={28} />
            </div>
            <p className="login-header-text-01">Lets get you set up</p>
            <p className="login-header-text-02">Your Personal Profile</p>
          </div>
          <form action="/profile/step2">
            <div>
              Title <br />
              <select>
                <option value="dr">Dr.</option>
                <option value="mr">Mr.</option>
                <option value="miss">Ms.</option>
                <option value="misses">Mrs.</option>
                <option value="prof">Prof.</option>
              </select>
            </div>
            <br />
            <div>
              Preferred Name <br />
              <input type="text" />
            </div>
            <br />
            <div>
              Preferred Gender <br />
              <select>
                <option value="m">Male</option>
                <option value="f">Female</option>
                <option value="x">Other</option>
              </select>
            </div>
            <br />
            <div>
              Date of Birth <br />
              <input type="date" />
            </div>
            <br />
            <div>
              Marital Status <br />
              <select>
                <option value="single">Single</option>
                <option value="married">Married</option>
                <option value="divorsed">Divorsed</option>
                <option value="widow">Widow</option>
              </select>
            </div>
            <br />
            <div>
              Personal Mobile Number <br />
              <input type="tel" />
            </div>
            <br />
            <input
              type="button"
              value="Save Progress"
              className="submit-secondary"
            />
            <input type="submit" value="Next" className="submit-primary" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default PersonalProfile;
