import React from "react";
import { withRouter } from "react-router";
import "./style/Settings.css";

const Settings = () => {
  return (
    <div className="dashboard">
      <div className="dash-body cal-left">
        <h2 className="centered">Settings Panel</h2>
        <br />
      </div>
      <div className="dash-body cal-right">
        <h2 className="centered">Sub Menu</h2>
        <div></div>
      </div>
    </div>
  );
};

export default withRouter(Settings);
